import ImageGallery from 'react-image-gallery';
import React from "react";
import pic1 from "../../../../src/img/site_pictures/VPB1.jpg";
import pic2 from "../../../../src/img/site_pictures/VPB2.jpg";
import pic3 from "../../../../src/img/site_pictures/VPB3.jpg";

export default class SimpleSlider extends React.Component {

  render() {

    const images = [{
        original: pic1, 
        thumbnail: "../src/img/site_pictures/pic1.jpg",
        sizes: "height=200px"
      },
      {
        original: pic2,
        thumbnail: "../src/img/site_pictures/pic2.jpg",
      },
      {
        original: pic3,
        thumbnail: "../src/img/site_pictures/pic3.jpg",
      }
    ]

    const images_mobile = [{
       original: pic1,
        thumbnail: "../src/img/site_pictures/pic1.jpg",
      },
      {
        original: pic2,
        thumbnail: "../src/img/site_pictures/pic2.jpg",
      },
      {
        original: pic3,
        thumbnail: "../src/img/site_pictures/pic3.jpg",
      }
    ]

    return (
      <div>
        <span className="largeParagraph">
          <ImageGallery items={images} showThumbnails={false} 
          showFullscreenButton={false} autoPlay={true} showPlayButton={false} 
          slideDuration={550} slideInterval={4000} useBrowserFullscreen={false}/>
        </span>
        <span className="smallParagraph">
          <ImageGallery items={images_mobile} showThumbnails={false} 
          showFullscreenButton={false} autoPlay={true} showPlayButton={false} 
          slideDuration={550} slideInterval={4000} useBrowserFullscreen={false}/>
        </span>
      </div>
    );
  }
}
