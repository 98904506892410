import Flux from "@4geeksacademy/react-flux-dash";
import FontAwesome from 'react-fontawesome';
import Footer from '../Footer.jsx';
import Navbar from '../Navbar.jsx';
import React from "react";
import Store from "../../store/Store.jsx";
import { Link, Redirect } from "react-router-dom";

export default class Vehicles extends Flux.View {

  constructor(props) {
    super(props);
    this.state = (
      Store.getLoggedInInfo()
    )
  }

  // Lifecycle methods

  componentWillMount() {
    this.setState(
      Store.getLoggedInInfo()
    )
    this.bindStore(Store, () => {
      this.setState(
        Store.getLoggedInInfo()
      )
    })
  }

  // Views Methods

  render() {
    if (this.state.uid === "") return (<Redirect to="login/vehicles"/>)

    return (
      <div>
        <Navbar active=""/>
        <div className="margin-style-user">
          <div id="contactPageCardStyle" className="card col-sm-12">
            <div className="margin-style-user-back">
              <Link to="/Profile"> 
                  <button type="button" className="btn btn-primary">
                    <FontAwesome name='arrow-left' size="1x"/>
                  </button>
              </Link> 
            </div>
          <div className="card card-heading text-center">
            <h1>Vehicles</h1>
          </div>
            <form id="paddingForm">
              <div className="form-row">
                <div className="form-group col-sm-12">
                  <label for="inputAddress2" className="labelClass">Make</label>
                  <input type="text" className="form-control" value={this.state.vehicles.make} />
                </div>
                <div className="form-group col-sm-12">
                  <label for="inputAddress" className="labelClass">Model</label>
                  <input type="text" className="form-control" value={this.state.vehicles.model} />
                </div>
                <div className="form-group col-sm-12">
                  <label for="inputAddress" className="labelClass">Color</label>
                  <input type="text" className="form-control" id="inputAddress" value={this.state.vehicles.color} />
                </div>
                <div className="form-group col-sm-12">
                  <label for="inputAddress" className="labelClass">Year</label>
                  <input type="text" className="form-control" id="inputAddress" value={this.state.vehicles.year} />
                </div>
                <div className="form-group col-sm-12">
                  <label for="inputAddress" className="labelClass">Tag Number</label>
                  <input type="text" className="form-control" id="inputAddress" value={this.state.vehicles.tagNumber} />
                </div>
              </div>
            </form>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
