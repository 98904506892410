import React from "react";
import Store from "../../store/Store.jsx";

export default class ThankYou extends React.Component {

    constructor(props) {
        super(props);
        this.state = (
            Store.getLoggedInInfo()
        )
    }

    // Lifecycle methods

    componentWillMount() {
        this.setState(
            Store.getLoggedInInfo()
        )
    }

    render() {
        return (
            <div>
            
                <h1 id="logincard-title-style">Thank you!</h1>
                
                <div className="form-row">
                    <div className="form-group col-sm-8">
                        <p>We'll get back to you shortly.</p>
                    </div>
                    <div className="form-group col-sm-8">
                        <a href="/"><button className="btn btn-primary">Return to Main Page</button></a>
                    </div>
                </div>
                
            </div>
        );
    }
}
