import Actions from "../../actions/Actions.jsx";
import Flux from "@4geeksacademy/react-flux-dash";
import FontAwesome from 'react-fontawesome';
import Footer from '../Footer.jsx';
import Navbar from '../Navbar.jsx';
import React from "react";
import {Session} from '../../actions/utility/session';
import Store from "../../store/Store.jsx";
import { Link, Redirect } from "react-router-dom";

export default class UserInformation extends Flux.View {

  constructor(props) {
    super(props);
 this.state = {
      contactInformation:{
        cell:'',
        house:'',
        business:''
      },
      email:'',
      petInformation: {
        name: '',
        breed: '',
        color: '',
        weight: ''
      },
      propertyAddress: {},
      userInformation: {
        firstName:'',
        lastName:'',
      },
      vehicleInformation: {
        make: '',
        model: '',
        color: '',
        year: '',
        tagNumber: ''
      }
    };
  }
 
 componentWillMount(){
    const userInfo = Session.store.getSession().user.info
    const user = Session.store.getSession()
    Actions.getEmail(user)
    Actions.getContactData(userInfo.peoplekey)
    Actions.getPropertyAddress(userInfo.accountnumber)
    
    this.setState ({ 
      userInformation: {
        firstName: userInfo.firstname,
        lastName: userInfo.lastname,
      }
    });
    
    this.bindStore(Store, () => {
      const propAdd = Store.getPropertyAddress()
      this.setState ({
        propertyAddress: propAdd
      })
    })
    this.bindStore(Store, () => {
      const email = Store.getEmail()
      this.setState ({
        email:email
      })
    })
    this.bindStore(Store, () => {
      const contactData = Store.getContactData()
      this.setState ({
        contactInformation: {
          cell:contactData.Home,
          house:contactData.Alternate,
          business:contactData.Work
        }
      })
    })
  }
  // Views Methods
  render() {
    return (
      <div>
        <Navbar active=""/>
        <div className="margin-style-user">
          <div id="contactPageCardStyle" className="card col-sm-12">
            <div>
              <span className="row d-flex justify-content-between">
                <span className="row">
                  <div id="space-between">
                    <Link to="/Profile"> 
                        <button type="button" className="btn btn-primary button-color">
                          <FontAwesome name='arrow-left' size="lg"/>
                        </button>
                    </Link>
                  </div>
                 {/* <button className="btn btn-primary button-color">
                    <FontAwesome name='save' size="lg"/>
                  </button> */}
                </span>
                
                <div id="indent-right" className="form-row">
                  <div>
                    <p className="boldify">&nbsp;</p>
                  </div>
                  <div>
                    <p readOnly>&nbsp;</p>
                  </div>
                </div>
              </span>
          </div>
          <div className="card card-heading text-center">
            <h1>User Information</h1>
          </div>
              <form id="paddingForm">
                <div className="form-row form-row-user-info">
                  <div className="form-group form-group-user-info col-sm-8">
                    <label htmlFor="inputAddress2" className="labelClass">First Name</label>
                    <input type="text" className="form-control" value={this.state.userInformation.firstName} />
                  </div>
                  <div className="form-group form-group-user-info col-sm-8">
                    <label htmlFor="inputAddress" className="labelClass">Last Name</label>
                    <input type="text" className="form-control" value={this.state.userInformation.lastName} />
                  </div>
                  <div className="form-group form-group-user-info col-sm-8">
                    <label htmlFor="inputAddress" className="labelClass">Property Address</label>
                    <input type="text" className="form-control" id="inputAddress" value={this.state.propertyAddress.address} />
                  </div>
                  <div className="form-group form-group-user-info col-sm-8">
                    <label htmlFor="inputAddress" className="labelClass">Apt#</label>
                    <input type="text" className="form-control" id="inputAddress" value={this.state.propertyAddress.address2} />
                  </div>
                  <div className="form-group form-group-user-info col-sm-8">
                    <label htmlFor="inputCity" className="labelClass">City</label>
                    <input type="text" className="form-control" id="inputCity" value={this.state.propertyAddress.city} />
                  </div>
                  <div className="form-group form-group-user-info col-sm-8">
                    <label htmlFor="inputState" className="labelClass">State</label>
                      <input type="text" className="form-control" id="inputState" value={this.state.propertyAddress.state} />
                  </div>
                    <div className="form-group form-group-user-info col-sm-8">
                      <label htmlFor="inputZip" className="labelClass">Zip</label>
                      <input type="text" className="form-control" id="inputZip" value={this.state.propertyAddress.zip} />
                    </div>
                  <div className="form-group form-group-user-info col-sm-8">
                    <label htmlFor="inputEmail4" className="labelClass">Email</label>
                    <input type="email" className="form-control" id="inputEmail4" value={this.state.email} />
                  </div>
                  <div className="form-group form-group-user-info col-sm-8">
                    <label htmlFor="inputAddress" className="labelClass">Cell Number</label>
                    <input type="text" className="form-control" id="cellNumber" value={this.state.contactInformation.cell} />
                  </div>
                  <div className="form-group form-group-user-info col-sm-8">
                    <label htmlFor="inputAddress" className="labelClass">House Number</label>
                    <input type="text" className="form-control" id="houseNumber" value={this.state.contactInformation.house}/>
                  </div>
                  <div className="form-group form-group-user-info col-sm-8">
                    <label htmlFor="inputAddress" className="labelClass">Business Number</label>
                    <input type="text" className="form-control" id="businessNumber" value={this.state.contactInformation.business}/>
                  </div>

                <div div className="form-group form-group-user-info col-sm-8">
                {/*  <label className="boldify" htmlFor="title">Lang</label>
                  <div className="btn-group profile-buttons-margin">
                    <button type="button" className="btn btn-primary dropdown-toggle button-color" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      English
                    </button>
                    <div className="dropdown-menu">
                      <a className="dropdown-item" href="#">Spanish</a>
                    </div> 
                  </div> */}
                </div>
                  
                </div>
              </form>          
            </div>
          </div> 
          <Footer />
      </div>
    );
  }
}
