import Actions from "../actions/Actions.jsx";
import Flux from "@4geeksacademy/react-flux-dash";
import FontAwesome from 'react-fontawesome';
import Footer from '../components/Footer.jsx';
import Navbar from '../components/Navbar.jsx';
import React from "react";
import Store from "../store/Store.jsx";
import {Session} from '../actions/utility/session';
import { Link, Redirect } from "react-router-dom";

export default class Architectural extends Flux.View {

  constructor(props) {
    super(props);
    this.state = (
      Store.getLoggedInInfo()
    )
    this.state = ({
      date: '',
      durationInDays: '',
      modificationRequested: '',
      companyName: '',
      contactName: '',
      streetAddress: '',
      addressLineTwo: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      email: '',
      buttonDisabled: false
    })
    this.bindStore(Store, () => {
      this.setState(
        Store.getLoggedInInfo()
      )
    })
  }

  // Lifecycle methods

  componentWillMount() {
    this.setState(
      Store.getLoggedInInfo()
    )
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleButtonDisabled(event) {
    this.setState({ buttonDisabled: event.target.value })
  }

  buttonNav(page, event) {
    event.preventDefault()
    Actions.updateStore({
      modificationPage: page
    })
  }

  isActive(value) {
    return 'col-sm-8 maximum-width ' + ((value === this.state.modificationPage) ? '' : 'd-none')
  }
  
  formSubmit(page, event){
    
    event.preventDefault()
    
    const sessionToken = Session.store.getSession().access_token;
    
    const data = {
      id:"",
      topsownerid:"123467",
      startdate: this.state.date,
      duration:  this.state.durationInDays, 
      modificationrequested: this.state.modificationRequested, 
      companyname: this.state.companyName,  
      contactname: this.state.contactName,  
      address: this.state.streetAddress,  
      address2: this.state.addressLineTwo, 
      city: this.state.city, 
      state: this.state.state,  
      zip: this.state.zip, 
      phone: this.state.phone,  
      email: this.state.email
    }
    
    const formResults = Actions.submitArchitectural(sessionToken, data)
    
    Actions.updateStore({
      modificationPage: page
    })
  }

  render() {

    // Form validation
    let startDate = this.state.date;

    let duration = this.state.durationInDays;

    let modification = this.state.modificationRequested;

    let company = this.state.companyName;

    let contact = this.state.contactName;

    let address = this.state.streetAddress;

    let addressTwo = this.state.addressLineTwo;

    let theCity = this.state.city;

    let theState = this.state.state;

    let zipCode = this.state.zip;

    let thePhone = this.state.phone;

    let theEmail = this.state.email;

    let disabled = this.state.buttonDisabled;

    const aPage = this.state.modificationPage;

    if (aPage === 'details') {
      disabled = (!this.state.buttonDisabled)
      if (startDate !== '' && duration !== '' && modification !== '' && company !== '' && contact !== '' &&
        address !== '' && theCity !== '' && theState !== '' && zipCode !== '' &&
        thePhone !== '' && theEmail !== '') {
        disabled = this.state.buttonDisabled
      }
    }

    return (
      <div>
            <Navbar active="architectural"/>
            
            <div className="largeArchitectural">
                <div className={this.isActive("request") + " section-height"}>
                    <div className="div-bottom-padding">
                        <h1 className="text-center">Architectural Modification Request</h1>
                        <p></p>
                        <p></p>
                        <p>Please be advised that in order to have your Architectural Modification request processed, our office must receive the documentation mentioned below.</p>
                        <p></p>
                        <ul className="numberedList">
                            <li><strong>1:</strong>&emsp;License for the company doing the modification</li>
                            <li><p></p></li>
                            <li><strong>2:</strong>&emsp;Proof of Worker's Compensation or Proof of Worker's Compensation Exemption</li>
                            <li><p></p></li>
                            <li><strong>3:</strong>&emsp;Certificate of Liability (Must show the Association as an additional insured)</li>
                            <li><p></p></li>
                            <li><strong>4:</strong>&emsp;Scope of Work</li>
                        </ul>
                        <p>
                           You will not be allowed to submit your request without these documents.
                        </p>
                        <div className="row d-flex justify-content-end" id="button-padding-right">
                            <button className="btn btn-primary button-color"  onClick={(event) =>{this.buttonNav("details",event)}}>Next&gt;</button>
                        </div>
                    </div>
                </div>
                
                <div className={this.isActive("details") + " section-height"}>
                    <div className="div-bottom-padding">
                        <div>
                            <h1 className="text-center">Request Details</h1>
                            <div>
                                <div className="row d-flex justify-content-between col-sm-8 div-bottom-padding">
                                    <span>
                                        <label><strong>Start Date<span className="asterisk">*</span></strong></label>
                                        <input className="form-control" name="date" type="text" onChange={this.handleChange.bind(this)} required/>
                                    </span>
                                    <span>
                                        <label><strong>Duration in Days<span className="asterisk">*</span></strong></label>
                                        <input className="form-control" name="durationInDays" type="text" onChange={this.handleChange.bind(this)} required/>
                                    </span>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="reason"><strong>Modification Being Requested.<span className="asterisk">*</span></strong></label>
                                    <textarea className="form-control" id="reason" name="modificationRequested" rows="5" onChange={this.handleChange.bind(this)} 
                                    placeholder="Please describe in details materials, colors, size, texture, etc." required>
                                    </textarea>
                                </div>
                                <form>
                                  <div className="form-group">
                                    <label><strong>Upload pictures (if applicable)</strong></label>
                                    <input type="file" className="form-control-file col-sm-4 card inputCard" />
                                  </div>
                                </form>
                            </div>
                        </div>
                        <div>
                        <h1 className="text-center">Contractor Information</h1>
                        <form id="paddingForm">
                          <div className="form-row">
                            <div className="form-group col-sm-6">
                              <label htmlFor="inputAddress2" className="labelClass">Company Name<span className="asterisk">*</span></label>
                              <input type="text" name="companyName" className="form-control" onChange={this.handleChange.bind(this)} />
                            </div>
                            <div className="form-group col-sm-6">
                              <label htmlFor="inputAddress" className="labelClass">Contact Name<span className="asterisk">*</span></label>
                              <input type="text" name="contactName" className="form-control" onChange={this.handleChange.bind(this)} />
                            </div>
                            <div className="form-group col-sm-6">
                              <label htmlFor="inputAddress" className="labelClass">Street Address<span className="asterisk">*</span></label>
                              <input type="text" name="streetAddress" className="form-control" id="inputAddress" onChange={this.handleChange.bind(this)} />
                            </div>
                            <div className="form-group col-sm-6">
                              <label htmlFor="inputAddress" className="labelClass">Address Line 2</label>
                              <input type="text" name="addressLineTwo" className="form-control" id="inputAddress" onChange={this.handleChange.bind(this)} />
                            </div>
                            <div className="form-row">
                            <div className="form-group col-sm-4">
                              <label htmlFor="inputCity" className="labelClass">City<span className="asterisk">*</span></label>
                              <input type="text" name="city" className="form-control" id="inputCity" onChange={this.handleChange.bind(this)} />
                            </div>
                            <div className="form-group col-sm-4">
                              <label htmlFor="inputState" className="labelClass">State<span className="asterisk">*</span></label>
                              <input type="text" name="state" className="form-control" id="inputState" onChange={this.handleChange.bind(this)} />
                            </div>
                              <div className="form-group col-sm-4">
                                <label htmlFor="inputZip" className="labelClass">Zip<span className="asterisk">*</span></label>
                                <input type="text" name="zip" className="form-control" id="inputZip" onChange={this.handleChange.bind(this)} />
                              </div>
                            </div>
                            <div className="form-group col-sm-6">
                              <label htmlFor="inputEmail4" className="labelClass">Phone Number<span className="asterisk">*</span></label>
                              <input type="email" name="phone" className="form-control" id="inputEmail4" onChange={this.handleChange.bind(this)} />
                            </div>
                            <div className="form-group col-sm-6">
                              <label htmlFor="inputAddress" className="labelClass">Email Address<span className="asterisk">*</span></label>
                              <input type="text" name="email" className="form-control" id="inputAddress" onChange={this.handleChange.bind(this)} />
                            </div>
                          </div>
                        </form>
                        
                        <div>
                            <form className="d-flex justify-content-between">
                              <div className="form-group">
                                <label><strong>Scope of Work</strong></label>
                                <input type="file" className="form-control-file card inputCard" />
                              </div>
                              <div className="form-group">
                                <label><strong>Company License</strong></label>
                                <input type="file" className="form-control-file card inputCard" />
                              </div>
                            </form>
                        </div>
                        
                        <div>
                            <form>
                              <div className="form-group">
                                <label><strong>Proof of Worker's Comp or Proof of Worker's Comp Exemption</strong></label>
                                <input type="file" className="form-control-file col-sm-4 card inputCard" />
                              </div>
                            </form>
                        </div>
                        
                        <div>
                            <form>
                              <div className="form-group">
                                <label><strong>Certificate of Liability (Association Must Be Noted As An Additional Insured)</strong></label>
                                <input type="file" className="form-control-file col-sm-4 card inputCard" />
                              </div>
                            </form>
                        </div>
                        
                        </div>
                        <div className="row d-flex justify-content-between col-sm-12">
                            <button className="btn btn-primary button-color" onClick={(event) =>{this.buttonNav("request",event)}}>&lt;Previous</button>
                            <button className="btn btn-primary button-color" disabled={disabled} onClick={(event) =>{this.formSubmit("thankYou",event)}}>Submit</button>
                        </div>
                    </div>
                    </div>
                    
                    <div className={this.isActive("thankYou") + " section-height"}>
                    <div className="container h-100 div-bottom-padding">
                      <h1 id="logincard-title-style" className="text-center">Thank you</h1>
                      <div className="row h-100 justify-content-center align-items-center">
                          <div className="form-group col-sm-8">
                              <p className="text-center">For submitting your architectural modification request.<br />
                              We will get back to you shortly.</p>
                          </div>
                          <div className="form-group col-sm-8 d-flex justify-content-center">
                              <a href="/"><button className="btn btn-primary button-color">Return to Home Page</button></a>
                          </div>
                      </div>
                      </div>
                    </div>
                   </div> 
                  
                  {/*Small Architectural*/}
           
                  <div className="smallArchitectural">
                  <div className={this.isActive("request") + " section-height"}>
                    <div className="div-bottom-padding">
                        <h1 className="text-center">Architectural Modification Request</h1>
                        <p>Please be advised that in order to have your Architectural Modification request processed, our office must receive the documentation mentioned below.</p>
                        <ol>
                            <li>License for the company doing the modification</li>
                            <li>Proof of Worker's Compensation or Proof of Worker's Compensation Exemption</li>
                            <li>Certificate of Liability (Must show the Association as an additional insured)</li>
                            <li>Scope of Work</li>
                        </ol>
                        <p>
                           You will not be allowed to submit your request without these documents.
                        </p>
                        <div className="row d-flex justify-content-end" id="button-padding-right">
                            <button className="btn btn-primary button-color"  onClick={(event) =>{this.buttonNav("details",event)}}>Next&gt;</button>
                        </div>
                    </div>
                </div>
                
                <div className={this.isActive("details") + " section-height"}>
                    <div className="div-bottom-padding">
                        <div>
                            <h1 className="text-center">Request Details</h1>
                            <div>
                                <div className="row d-flex justify-content-between col-sm-8 div-bottom-padding">
                                    <span>
                                        <label><strong>Start Date<span className="asterisk">*</span></strong></label>
                                        <input className="form-control" name="date" type="text" onChange={this.handleChange.bind(this)} required/>
                                    </span>
                                    <span>
                                        <label><strong>Duration in Days<span className="asterisk">*</span></strong></label>
                                        <input className="form-control" name="durationInDays" type="text" onChange={this.handleChange.bind(this)} required/>
                                    </span>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="reason"><strong>Modification Being Requested.<span className="asterisk">*</span></strong></label>
                                    <textarea className="form-control" id="reason" name="modificationRequested" rows="3" onChange={this.handleChange.bind(this)} 
                                    placeholder="Please describe in details materials, colors, size, texture, etc." required>
                                    </textarea>
                                </div>
                                <form>
                                  <div className="form-group">
                                    <label><strong>Upload pictures (if applicable)</strong></label>
                                    <input type="file" className="form-control-file col-sm-4 card inputCard" />
                                  </div>
                                </form>
                            </div>
                        </div>
                        <div>
                        <h1 className="text-center">Contractor Information</h1>
                        <form id="paddingForm">
                          <div className="form-row form-row-user-info">
                            <div className="form-group form-group-user-info col-sm-6">
                              <label htmlFor="companyName" className="labelClass">Company Name<span className="asterisk">*</span></label>
                              <input type="text" name="companyName" className="form-control" onChange={this.handleChange.bind(this)} />
                            </div>
                            <div className="form-group form-group-user-info col-sm-6">
                              <label htmlFor="contactName" className="labelClass">Contact Name<span className="asterisk">*</span></label>
                              <input type="text" name="contactName" className="form-control" onChange={this.handleChange.bind(this)} />
                            </div>
                            <div className="form-group form-group-user-info col-sm-6">
                              <label htmlFor="streetAddress" className="labelClass">Street Address<span className="asterisk">*</span></label>
                              <input type="text" name="streetAddress" className="form-control" id="inputAddress" onChange={this.handleChange.bind(this)} />
                            </div>
                            <div className="form-group form-group-user-info col-sm-6">
                              <label htmlFor="addressLineTwo" className="labelClass">Address Line 2</label>
                              <input type="text" name="addressLineTwo" className="form-control" id="inputAddress" onChange={this.handleChange.bind(this)} />
                            </div>
                            <div className="form-row">
                            <div className="form-group form-group-user-info col-sm-4">
                              <label htmlFor="city" className="labelClass">City<span className="asterisk">*</span></label>
                              <input type="text" name="city" className="form-control" id="inputCity" onChange={this.handleChange.bind(this)} />
                            </div>
                            <div className="form-group form-group-user-info col-sm-4">
                              <label htmlFor="state" className="labelClass">State<span className="asterisk">*</span></label>
                              <input type="text" name="state" className="form-control" id="inputState" onChange={this.handleChange.bind(this)} />
                            </div>
                              <div className="form-group form-group-user-info col-sm-4">
                                <label htmlFor="zip" className="labelClass">Zip<span className="asterisk">*</span></label>
                                <input type="text" name="zip" className="form-control" id="inputZip" onChange={this.handleChange.bind(this)} />
                              </div>
                            </div>
                            <div className="form-group form-group-user-info col-sm-6">
                              <label htmlFor="phone" className="labelClass">Phone Number<span className="asterisk">*</span></label>
                              <input type="text" name="phone" className="form-control" id="inputEmail4" onChange={this.handleChange.bind(this)} />
                            </div>
                            <div className="form-group form-group-user-info col-sm-6">
                              <label htmlFor="email" className="labelClass">Email Address<span className="asterisk">*</span></label>
                              <input type="text" name="email" className="form-control" id="inputAddress" onChange={this.handleChange.bind(this)} />
                            </div>
                          </div>
                        </form>
                        
                        <div>
                            <form>
                              <div className="form-group">
                                <label><strong>Scope of Work</strong></label>
                                <input type="file" className="form-control-file card inputCard" />
                              </div>
                              <div className="form-group">
                                <label><strong>Company License</strong></label>
                                <input type="file" className="form-control-file card inputCard" />
                              </div>
                            </form>
                        </div>
                        
                        <div>
                            <form>
                              <div className="form-group">
                                <label><strong>Proof of Worker's Comp or Proof of Worker's Comp Exemption</strong></label>
                                <input type="file" className="form-control-file col-sm-4 card inputCard" />
                              </div>
                            </form>
                        </div>
                        
                        <div>
                            <form>
                              <div className="form-group">
                                <label><strong>Certificate of Liability (Association Must Be Noted As An Additional Insured)</strong></label>
                                <input type="file" className="form-control-file col-sm-4 card inputCard" />
                              </div>
                            </form>
                        </div>
                        
                        </div>
                        <div className="row d-flex justify-content-between col-sm-12 row-mobile">
                            <button className="btn btn-primary button-color" onClick={(event) =>{this.buttonNav("request",event)}}>&lt;Previous</button>
                            <button className="btn btn-primary button-color" disabled={disabled} onClick={(event) =>{this.formSubmit("thankYou",event)}}>Submit</button>
                        </div>
                    </div>
                    </div>
                    
                    <div className={this.isActive("thankYou") + " section-height"}>
                    <div className="container h-100 div-bottom-padding">
                      <h1 id="logincard-title-style" className="text-center">Thank you</h1>
                      <div className="row h-100 justify-content-center align-items-center">
                          <div className="form-group col-sm-8">
                              <p className="text-center">For submitting your architectural modification request.<br />
                              We will review the request and the documentation provided and will get back to you shortly.</p>
                          </div>
                          <div className="form-group col-sm-8 d-flex justify-content-center">
                              <a href="/"><button className="btn btn-primary button-color">Return to Home Page</button></a>
                          </div>
                      </div>
                      </div>
                    </div>
                  </div>
            <Footer/>
          </div>
    );
  }
}
