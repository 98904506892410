/* global $ */
import Flux from "@4geeksacademy/react-flux-dash";
import Footer from '../components/Footer.jsx';
import Navbar from '../components/Navbar.jsx';
import React from "react";
import Store from "../store/Store.jsx";
import { Redirect } from "react-router-dom";
import Actions from "../actions/Actions.jsx";
import {Session} from '../actions/utility/session';

export default class PayNow extends Flux.View {
    constructor(props) {
        super(props);
        this.state = {
            processToken: false, 
            token: '',
            data: {
                    'landing': 'pay',
                    'navbar': true,
                    'menu': {
                        'display': true,
                        'showlabel': true,
                        'position': 'left',
                        'tabs': {
                            //side bar or main menu
                            'Dashboard': true,
                            'Make a Payment': true,
                            'Manage AutoPay': true,
                            'My Payment Methods': true,
                            'Payment History': true,
                            'My Profile': false,
                            'Help Request': false,
                        }
                    },
                    'payor': {
                        'account_number': '' //required
                    },
                    'customize': {
                        'style':'https://www.vintageestatesdoral.com/RevoStyle.css'
                    },
                    'scope': {
                        'companyID': '0121', //Company ID
                        'paypointID': '1212235' //Property ID
                    },
                    'methods': {
                        'cc': true,
                        'ec': false,
                        'amex': true,
                        'autopay': true,
                        'onetime': true,
                        'autopayOptions': {
                            'dynamic': false,
                            'freq': {},
                            'dynamicfreq': {},
                            'daysAllowed': '12-14',
                            'dynamicdaysAllowed': '15-18|25|26|27-30',
                            'yearsCount': 2,
                        }
                    },
                   'payment': {
                        'overrideCategories': true, //default false
                        'categories': [{}]
                }
            }
        }
    }

    // Lifecycle methods

    componentDidMount() {
        const userInformation = Session.store.getSession().user.info;
        this.bindStore(Store, () => {
            const revoAcctNumber = Store.getRevoAccountNumber()
            const oldData = this.state.data;
            const newData =  {
                'payor': {
                    'account_number': revoAcctNumber //required
                }
            };
            this.setState ({
                data: Object.assign(oldData, newData)
            });
        })
        const currBal = Store.getCurrentBalance()
        const oldData = this.state.data;
        const newData =  {
            'payment': {
                'overrideCategories': true,
                'categories':[{
                    'name': 'Balance Due',
                    'amount': currBal,
                    'save': true,
                    'is_balance': true,
                    'selected': true
                    },
                    {
                    'name': 'Monthly Assessment',
                    'amount': 97.67,
                    'save': false,
                    'is_balance': true
                    },
                    {
                    'name': 'Other Amount',
                    'amount': '',
                    'save': false,
                    'is_balance': true
                    }
                ]}
            };
            this.setState ({
                data: Object.assign(oldData, newData)
            });
        
        const sessionToken = Session.store.getSession().access_token;
        Actions.getRevoToken(userInformation.topsownerid,sessionToken,this.state.data.payor.account_number)
        this.bindStore(Store, () => {
            const revoTn = Store.getRevoToken()
            this.setState ({
                token: revoTn
            })
        })
       
    }
    // Views Methods

    render() {
        if (this.state.token === '' ) {
            return (<h2>Loading</h2>)
        }  else {
            $('#revoIframe').bundle(this.state.token, this.state.data);
        }
        
        return (
            <div className="d-flex flex-column">
                <Navbar active=""/>
                <div id="Revobody">
                    <iframe id="revoIframe" src="">your browser needs to be updated.</iframe>
                </div>
            </div>
        );
    }
}
