import Flux from "@4geeksacademy/react-flux-dash";
import FontAwesome from 'react-fontawesome';
import Footer from '../Footer.jsx';
import Navbar from '../Navbar.jsx';
import React from "react";
import Store from "../../store/Store.jsx";
import { Link, Redirect } from "react-router-dom";


export default class Financials extends Flux.View {
  constructor(props) {
    super(props);
    this.state = (
      Store.getLoggedInInfo()
    )
  }

  // Lifecycle methods

  componentWillMount() {
    this.setState(
      Store.getLoggedInInfo()
    )
    this.bindStore(Store, () => {
      this.setState(
        Store.getLoggedInInfo()
      )
    })
  }

  // Views Methods

  render() {
    
    return (
      <div>
        <Navbar active=""/>
          <span className="smallStatement">
              <div className="card" id="body">
                <div className="margin-style-user-back-btn">
                  <Link to="/Documents"> 
                      <button type="button" className="btn btn-primary button-color">
                        <FontAwesome name='arrow-left' size="lg"/>
                      </button>
                  </Link> 
                </div>
                <div className="card div-profile text-center"><h1> Financials </h1></div>
                  <div className="margin-span-style">
                    <ul className="menu-items">
                     <li className="menu-items">
                          <a className="parent" href="https://fs23.formsite.com/AFFINITY/files/f-33-4-10339199_z6w3Kci6_2018_1130_Financial_Report_Website.pdf" target="_blank">
                            <FontAwesome name='file-alt' size="lg"/>
                              &nbsp;November Financial Report
                            <span className="float-right">
                              <FontAwesome name='chevron-right' size="lg"/>
                            </span>
                          </a>
                        </li>
                           <li className="menu-items">
                          <a className="parent" href="https://fs23.formsite.com/AFFINITY/files/f-33-3-10339199_8GuEvObw_2018_1031_Financial_Report_Website.pdf" target="_blank">
                            <FontAwesome name='file-alt' size="lg"/>
                              &nbsp;October Financial Report
                            <span className="float-right">
                              <FontAwesome name='chevron-right' size="lg"/>
                            </span>
                          </a>
                        </li>
                       <li className="menu-items">
                          <a className="parent" href="https://fs23.formsite.com/AFFINITY/files/f-33-2-10339199_GzcwCRQV_2018_0930_Financial_Report_Website.pdf" target="_blank">
                            <FontAwesome name='file-alt' size="lg"/>
                              &nbsp;September Financial Report
                            <span className="float-right">
                              <FontAwesome name='chevron-right' size="lg"/>
                            </span>
                          </a>
                        </li>
                        <li className="menu-items">
                          <a className="parent" href="https://fs23.formsite.com/AFFINITY/files/f-6-0-10138175_BTpRiNoa_2018_0831_Financial_Report_Village_of_Palm_Bay_website.pdf" target="_blank">
                            <FontAwesome name='file-alt' size="lg"/>
                              &nbsp;August Financial Report
                            <span className="float-right">
                              <FontAwesome name='chevron-right' size="lg"/>
                            </span>
                          </a>
                        </li>
                        <li className="menu-items">
                          <a className="parent" href="https://fs23.formsite.com/AFFINITY/files/f-6-0-10102082_Mj4NrBFZ_2018_0731_Financial_Report_Village_of_Palm_Bay_WEB.pdf" target="_blank">
                            <FontAwesome name='file-alt' size="lg"/>
                              &nbsp;July Financial Report
                            <span className="float-right">
                              <FontAwesome name='chevron-right' size="lg"/>
                            </span>
                          </a>
                        </li> 
                        <li className="menu-items">
                          <a className="parent" href="https://fs23.formsite.com/AFFINITY/files/f-6-0-10102080_8GDtJJ0i_2018_0630_Financial_Report_Village_of_Palm_Bay_WEB.pdf" target="_blank">
                            <FontAwesome name='file-alt' size="lg"/>
                              &nbsp;June Financial Report
                            <span className="float-right">
                              <FontAwesome name='chevron-right' size="lg"/>
                            </span>
                          </a>
                        </li>
                        <li className="menu-items">
                          <a className="parent" href="https://fs23.formsite.com/AFFINITY/files/f-6-0-10102079_DxiZHvtM_2018_0531_Final_Financial_Report_Village_of_Palm_Bay_WEB.pdf" target="_blank">
                            <FontAwesome name='file-alt' size="lg"/>
                              &nbsp;May Financial Report
                            <span className="float-right">
                              <FontAwesome name='chevron-right' size="lg"/>
                            </span>
                          </a>
                        </li>
                    </ul>
                  </div>
                </div>
            </span>
        <Footer />
      </div>
    );
  }
}
