import Flux from "@4geeksacademy/react-flux-dash";
import React from 'react';
import Store from "./store/Store.jsx"
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {PrivateRoute} from './actions/utility/session';

import Actions from "./actions/Actions.jsx";
import Amenities from "./views/Amenities.jsx";
import Architectural from "./views/Architectural.jsx";
import ClubhouseRental from "./views/ClubhouseRental.jsx";
import Contact from './views/Contact.jsx';
import Dispute from './views/Dispute.jsx';
import Documents from "./views/Documents.jsx";
import Events from "./views/Events.jsx";
import Financials from "./components/documents/Financials.jsx";
import GoverningDocuments from './components/documents/GoverningDocuments.jsx';
import History from './components/profile/History.jsx';
import Home from "./views/Home.jsx";
import Login from "./views/Login.jsx";
import LoginUpdate from './components/profile/LoginUpdate.jsx';
import Meetings from './components/documents/Meetings.jsx';
import Notices from './components/documents/Notices.jsx';
import PayNow from './views/PayNow.jsx';
import Pay from './views/Pay.jsx';
import PayTrash from './views/PayTrash.jsx';
import Upload from './views/Upload.jsx';
import Pets from './components/profile/Pets.jsx';
import Profile from './views/Profile.jsx';
import Statement from './views/Statement.jsx';
import UserInformation from './components/profile/UserInformation.jsx';
import Vehicles from './components/profile/Vehicles.jsx';


export default class Router extends Flux.View {
  constructor() {
    super();
  }
  //Lifecycle methods

  render() {
    return (
      <div>
        <BrowserRouter>
          <div>
            <Switch>
              <Route exact path="/amenities" component={Amenities} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/events" component={Events} />
              <PrivateRoute exact path="/architectural" component={Architectural} />
              <PrivateRoute exact path="/clubhouse-rental" component={ClubhouseRental} />
              <PrivateRoute exact path="/dispute" component={Dispute} />
              <PrivateRoute exact path="/documents" component={Documents} />
              <PrivateRoute exact path="/financials" component={Financials} />
              <PrivateRoute exact path="/governing-documents" component={GoverningDocuments} />
              <PrivateRoute exact path="/history" component={History} />
              <Route exact path="/home" component={Home} />
              <Route exact path="/index.html" component={Home} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/login/:nextP" component={Login} />
              <PrivateRoute exact path="/login-update" component={LoginUpdate} />
              <PrivateRoute exact path="/meetings" component={Meetings} />
              <PrivateRoute exact path="/upload" component={Upload} />
              <PrivateRoute exact path="/notices" component={Notices} />
              <PrivateRoute exact path="/pay-now" component={PayNow} />
              <PrivateRoute exact path="/pay" component={Pay} />
              <PrivateRoute exact path="/pay-trash" component={PayTrash} />
              <PrivateRoute exact path="/Pets" component={Pets} />
              <PrivateRoute exact path="/profile" component={Profile} />
              <PrivateRoute exact path="/statement" component={Statement} />
              <PrivateRoute exact path="/user-information" component={UserInformation} />
              <PrivateRoute exact path="/vehicles" component={Vehicles} />
              <Route component={Home} />
              <Route exact path="/" component={Home} />
            </Switch>
          </div>
        </BrowserRouter>
      </div>
    );
  }
}
