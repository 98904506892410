import BigCalendar from 'react-big-calendar';
import Flux from "@4geeksacademy/react-flux-dash";
import Footer from '../components/Footer.jsx';
import Navbar from '../components/Navbar.jsx';
import React from 'react';
import Store from "../store/Store.jsx";
import Moment from 'moment';

BigCalendar.momentLocalizer(Moment);

let allViews = Object.keys(BigCalendar.Views).map(k => BigCalendar.Views[k]);

export default class Events extends Flux.View {
  constructor() {
    super();
    this.state = {
      myEvents: [{
        id: 0,
        title: '',
        allDay: '',
        startDate: '',
        endDate: '',
      }]
    };
  }

//myEvents: [{
//        id: 0,
//        title: '',
//        allDay: true,
//        startDate: new Date(2015, 3, 0),
//        endDate: new Date(2015, 3, 1),
//      }]

  // Need to come back to this one. Currently hard coded

  componentWillMount() {}

  handleStoreChanges() {}

  render() {

    const MyCalendar = props => (
      <div>
    <BigCalendar
      events={this.state.myEvents}
      startAccessor='startDate'
      endAccessor='endDate'
      step={60}
      showMultiDayTimes
      defaultDate={new Date(2015, 3, 1)}
      views={allViews}
      style={{height: 400}}
    />
    </div>
    );
    return (
      <div>
        <Navbar active="events" />
        <div id="body">
          <div id="calendarDiv">
            <h1 className="card eventCardSyle text-center">Community Calendar of Events</h1>
            <div className="d-flex p-2 justify-content-center row">
              <div className="card" id="eventsCard">
              <MyCalendar/>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
    );
  }
}
