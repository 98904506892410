import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Flux from "@4geeksacademy/react-flux-dash";
import Footer from '../components/Footer.jsx';
import Navbar from '../components/Navbar.jsx';
import React from "react";
import Store from "../store/Store.jsx";
import BoardTable from "../components/BoardTable.jsx";

export default class Contact extends Flux.View {

  constructor(props) {
    super(props);
    this.state = (
      Store.getLoggedInInfo()
    )
  }
  // Lifecycle methods

  componentWillMount() {
    this.setState(
      Store.getLoggedInInfo()
    )
  }

  // Views Methods

  render() {
    return (
      <div> 
        <Navbar active="contact"/>
          <div id="body">
           <div id="aboutOurCommunity" className="card">
            <div className="max-width-statement">
              <h1 className="header-padding-bottom">Board of Directors</h1>
                <BoardTable />
              </div>
            </div>
            <div id="aboutOurCommunity" className="card contact-info">
              <p></p>
              <h1 className="header-padding-bottom">Professionally Managed By</h1>
              <p>
              Affinity Management Services<br/>
              8200 NW 41st ST<br/>
              Doral, FL 33166<br/>
              Phone: (305) 305-325-4243<br/>
              <a href="mailto:CustomerCare@ManagedByAffinity.com">
    					   <span className="text">EMail: CustomerCare@ManagedByAffinity.com</span>
    					   <span className="icon"><i className="fas fa-envelope"></i></span>
  					   </a>
              </p>
              <p></p>
              <p></p>
              
            </div>
          </div>
        <Footer />
      </div>
    );
  }
}
