import Actions from "../../actions/Actions.jsx";
import React from "react";
import Store from "../../store/Store.jsx";

export default class RequestAccess extends React.Component {

    constructor(props) {
        super(props);
        this.state = (
            Store.getLoggedInInfo()
        )
    }

    // Lifecycle methods

    componentWillMount() {
        this.setState(
            Store.getLoggedInInfo()
        )
    }

    // Views methods

    buttonNav(page, event) {
        event.preventDefault()
        Actions.updateStore({
            registerActivePage: page
        })
    }

    render() {
        return (
            <div>
                <h1 id="logincard-title-style">Request Access</h1>
                <div className="form-group">
                    <div className="form-group col-sm-10">
                      <label htmlFor="inputAddress2" className="labelClass">Name</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="form-group col-sm-10">
                      <label htmlFor="inputAddress" className="labelClass">Address</label>
                      <input type="text" className="form-control" id="inputAddress" />
                    </div>
                    <div className="form-group col-sm-10">
                      <label htmlFor="phoneNumber" className="labelClass">Phone</label>
                      <input type="text" className="form-control" id="phoneNumber" />
                    </div> 
                    <div className="form-group col-sm-10">
                      <label htmlFor="email" className="labelClass">Email</label>
                      <input type="text" className="form-control" id="email" />
                    </div>
                </div>
                <div className="form-group col-sm-7">
                    <button className="btn btn-primary" onClick={(event) =>{this.buttonNav("thankYou",event)}} type="submit">Submit</button>
                </div>
            </div>
        );
    }
}
