import Flux from "@4geeksacademy/react-flux-dash";
import FontAwesome from 'react-fontawesome';
import Footer from '../Footer.jsx';
import Navbar from '../Navbar.jsx';
import React from "react";
import { Link } from "react-router-dom";

export default class History extends Flux.View {

  render() {

    return (
      <div>
        <Navbar active=""/>
        <div className="margin-style-user">
        <div id="contactPageCardStyle" className="card col-sm-6">
        <div className="card card-heading text-center">
          <h1>History</h1>
        </div>
          <table className="table row-margin tableStyle">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Description</th>
                <th scope="col">Location</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>April 30, 2018</td>
                <td>Placeholder1</td>
                <td>Casa Del Mar</td>
              </tr>
              <tr>
                <td>April 26, 2018</td>
                <td>Placeholder@1</td>
                <td>Doral</td>
              </tr>
              <tr>
                <td>April 19, 2018</td>
                <td>Placeholder@g3</td>
                <td>Coral Gables</td>
              </tr>
            </tbody>
          </table>
            <div className="margin-style-user">
              <Link to="/Profile"> 
                  <button type="button" className="btn btn-primary">
                    <FontAwesome name='chevron-left' size="1x"/>
                      &nbsp;Back
                  </button>
              </Link> 
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
