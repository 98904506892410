import Flux from "@4geeksacademy/react-flux-dash";
import Footer from '../components/Footer.jsx';
import Navbar from '../components/Navbar.jsx';
import React from "react";
import Store from "../store/Store.jsx";

export default class Amenities extends Flux.View {

  constructor(props) {
    super(props);
    this.state = (
      Store.getLoggedInInfo()
    )
  }

  // Lifecycle methods

  componentWillMount() {
    this.setState(
      Store.getLoggedInInfo()
    )
  }

  // Views Methods

  render() {

    return (
      <div>
        <Navbar active="amenities"/>
        <div id="body">
          blank for now
        </div>
        <Footer />
      </div>
    );
  }
}
