import React from 'react';
import Dropzone from 'react-dropzone';
import Navbar from '../components/Navbar.jsx';
import Actions from '../actions/Actions.jsx';
import {Notifier} from '../actions/utility/notifier';

export default class Upload extends React.Component{
    
    constructor(){
        super();
        this.state = {
            accepted: [],
            rejected: []
        };
        this.dropzoneRef = null;
    }
    
    render(){
        return (<div>
            <Navbar active="upload"/>
            <div id="body">
                <Notifier />
                <form>
                    <div className="dropzone">
                        <Dropzone 
                            maxSize={1000000}
                            accept="image/jpeg, image/png, application/pdf" //find more mime types here: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Complete_list_of_MIME_types
                            ref={(node) => { this.dropzoneRef = node; }}
                            onDrop={(accepted, rejected) => this.setState({ accepted, rejected })}
                        >
                        <p>Try dropping some files here, or click to select files to upload.</p>
                      </Dropzone>
                      <button type="button" onClick={() => { this.dropzoneRef.open() }}>
                          Open File Dialog
                      </button>
                    </div>
                    <aside>
                      <h2>Accepted Files</h2>
                      <ul>
                        {
                          this.state.accepted.map(f => <li key={f.name}>{f.name} - {f.size} bytes</li>)
                        }
                      </ul>
                      <h2>Rejected Files</h2>
                      <ul>
                        {
                          this.state.rejected.map(f => <li key={f.name}>{f.name} - {f.size} bytes</li>)
                        }
                      </ul>
                    </aside>
                    <button type="button" onClick={() => Actions.uploadFiles(this.state.accepted)}>Submit</button>
                </form>
            </div>
        </div>);
    }
}