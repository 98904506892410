import Flux from '@4geeksacademy/react-flux-dash';


class Store extends Flux.Store {

    constructor() {
        super();
        this.state = {
            currentBalance:"",
            contactInformation:"",
            displayName: "",
            email:"",
            history: "",
            password: "",
            peopleKey: "",
            pets: "",
            propertyAddress:{},
            registerActivePage: "login",
            registerActiveMobilePage: "loginMobile",
            disputePage: "disputeForm",
            modificationPage: "request",
            displaySection: "pageOneConditionOne",
            resetEmail: "false",
            revoAccountNumber:"",
            revoToken:"",
            transactions: [],
            transactionsDate:"",
            uid: "",
            userInformation: "",
            vehicles: ""
        }
    }

    //Action Methods

    _resetSendView() {
        this.setStoreState({ resetEmail: "false" }).emit()
    }
    
    _setContactInformation(data){
        let contactData = {}
        for (let i = 0; i < data.length; i++) {
            const key = data[i].contacttype
            if (key == 'altAddress') {
                const oldData = contactData
                const newData = { 
                    altAddress: {
                        address: data[i].address,
                        address2: data[i].address2,
                        city: data[i].city,
                        state: data[i].state,
                        zip: data[i].zip
                    }
                }
                contactData : Object.assign(oldData,newData)
            } else if (key == 'email') {
                const oldData = contactData
                const newData = {email: data[i].email}
                contactData : Object.assign(oldData,newData)
            } else {
                const oldData = contactData
                const newData = {}
                newData[data[i].topsphonetype] = data[i].phone
                contactData : Object.assign(oldData,newData)
            }
            
        }
        
        this.setStoreState({ contactInformation:contactData }).emit()
        console.log("here")
    }
    
    _setPossesionData(data){
        this.setStoreState({
                currentBalance:data[0].currentbalance,
                revoAccountNumber:data[0].revoaccountnumber
            })
            .emit()
    }
    _setPropertyAddress(data){
        this.setStoreState({
            propertyAddress: {
                address:data[0].address,
                address2:data[0].address2,
                city:data[0].city,
                state:data[0].state,
                zip:data[0].zip,
            }
        })
        .emit()
    }
    _setRevoToken(data){
        this.setStoreState({
                revoToken: data.token
            })
            .emit()
    }
    _setState(data) {
        this.setStoreState({
                uid: data.info.id,
                displayName: data.info.firstname,
                peopleKey: data.info.peoplekey
            })
            .emit()
    }
    _setTransactions(data){
        const today = new Date().toJSON().slice(0,10).replace(/-/g,'/')
        this.setStoreState({
                transactions:data,
                transactionsDate:today
            })
            .emit()
    }
    
    _setUserData(data) {
        this.setStoreState({
                history: data.history,
                pets: data.pets,
                userInformation: data.userInformation,
                vehicles: data.vehicles,
                transactions: data.transactions
            })
            .emit()
    }

    _updateState(update) {
        this.setStoreState(update)
            .emit()
        console.log(this.state.registerActivePage)
    }
    _updateStateEmail(data) {
        this.setStoreState({email:data.email})
            .emit()
    }

// Views methods

    getContactData() {
        const contactData = this.state.contactInformation
        return contactData
    } 
    getCurrentBalance() {
        const currentBalance = this.state.currentBalance
        return currentBalance
    }   
    getEmail(){
        let email = this.state.email
        return email
    }
    getLoggedInInfo(){
        const userInfo = this.state
        return userInfo
    }
    getPropertyAddress() {
        const propertyAddress = this.state.propertyAddress
        return propertyAddress
    }
    getRevoAccountNumber() {
        const revoAccountNumber = this.state.revoAccountNumber
        return revoAccountNumber
    }
    getRevoToken() {
        const revoToken = this.state.revoToken
        return revoToken
    }
    getTransactions() {
        const transactions = this.state.transactions
        const transactionsDate = this.state.transactionsDate
        const transactionsInfo = [transactions, transactionsDate] 
        return transactionsInfo
    }
    saveAddressToStore(add) {
        this.setStoreState({ address: add }).emit()
    }
}
    
var store = new Store();
export default store;
