import Actions from "../actions/Actions.jsx";
import Flux from "@4geeksacademy/react-flux-dash";
import FontAwesome from 'react-fontawesome';
import Footer from '../components/Footer.jsx';
import Navbar from '../components/Navbar.jsx';
import React from "react";
import Store from "../store/Store.jsx";
import {Session} from '../actions/utility/session';
import { Link, Redirect } from "react-router-dom";


export default class Dispute extends Flux.View {
 render() {

        return (
            <div>
            <Navbar active="dispute"/>
            <div id="body" className="card contact-info">
                <div>
                    <div className="div-bottom-padding">
                        <h1 className="text-center">Clubhouse Reservation</h1>
                        <p></p>
                        <p>Welcome to the Vintage Estates Clubhouse reservation page. Here you'll find the information you need to reserve the clubhouse for your upcoming event.</p>
                        <p></p>
                        <p>Your board of directors and management company have worked diligently on developing a procedure that is both easy to follow and that protects the interests of the community.
                        We kindly ask that you take a few minutes to familiarize yourself with all of the instructions so that you are well aware of the rules and regulations and abide by them before, during and after your
                        event.
                        </p>
                        <p></p>
                        <p></p>
                        <h1>Steps to follow:</h1>
                        <p></p>
                        <ul className="numberedList">
                            <li><strong>1:</strong>&emsp;Download, read and fill out the reservation agreement by <a href="https://fs23.formsite.com/AFFINITY/files/f-6-0-10099892_zhy6doA1_2018-0213_Clubhouse_Rental_Agreement.pdf" target="_balnk">CLICKING HERE</a></li>
                            <li><p></p></li>
                            <li><strong>2:</strong>&emsp;Turn in the completed form to the management office along with your fee and refundable deposit at least 2 weeks before the event</li>
                            <li><p></p></li>
                            <li><strong>3:</strong>&emsp;During the event follow all the rules as outlined in the agreement</li>
                            <li><p></p></li>
                            <li><strong>4:</strong>&emsp;After the event, ensure that premises are clean and trash is removed</li>
                            <li><p></p></li>
                        </ul>
                        <p></p>
                        <p>And that's it! Once we verify that everything is in order we will refund your deposit.</p>
                        <p>We thank you for your cooperation and wish you a successfull event.</p>
                    </div>
                  </div>
                </div>
            <Footer/>
          </div>
        );
    }
}
