import Flux from '@4geeksacademy/react-flux-dash';
import React from 'react';
import {POST, GET, PUT, UPLOAD} from './utility/api';
import {Notify} from './utility/notifier';
import {Session} from './utility/session';
import Store from "../store/Store.jsx"

class Actions extends Flux.Action {


    login(email, password,nextPage, history) {
        
        POST('Users/login', { email, password })
            .then((data)=>{
                const access_token = data.id;
                let user = {
                    id: data.userId,
                    info: {
                        firstName: ''
                    }
                };
                GET('People/findOne?filter[where][uid]='+user.id)
                    .then((data)=>{
                        user.info = data;
                        Session.actions.login({ user, access_token });
                        history.push(nextPage) 
                    })
                    .catch(function(error) {
                        Notify.error(error.message || error);
                        alert("Incorrect Password. Please try again");
                    });
            })
            .catch(function(error) {
            Notify.error(error.message || error);
            //console.error(error);
        });
    }
    
    getContactData(peopleKey){
        GET('Contacts?filter[where][peoplekey]='+peopleKey+'')
            .then((contactData)=>{
                this.dispatch("Store.setContactInformation", contactData)
            })
            .catch(function(error) {
                Notify.error(error.message || error);
                console.error(error);
            });
    }
    
    getEmail(user){
        GET('users/'+user.user.id+"?access_token="+user.access_token)
            .then((data)=>{
                this.dispatch("Store.updateStateEmail", data)
            })
            .catch(function(error) {
                Notify.error(error.message || error);
                console.error(error);
            });
    }
    
    getPossesionData(topsOwnerID){
        GET('possesions/?filter[where][topsownerid]='+topsOwnerID)
            .then((data)=>{
                this.dispatch("Store.setPossesionData", data)
            })
            .catch(function(error) {
                Notify.error(error.message || error);
                console.error(error);
            });
    }
    
    getPropertyAddress(accountNumber){
        GET('Units/?filter[where][accountnumber]='+accountNumber)
            .then((address)=>{
                this.dispatch("Store.setPropertyAddress", address)
            })
            .catch(function(error) {
                Notify.error(error.message || error);
                console.error(error);
            });
    }
    
    getRevoToken(topsOwnerID,token){
        GET('possesions/?filter[where][topsownerid]='+topsOwnerID)
            .then((data)=>{
                const revoAccountNumber = data[0].revoaccountnumber
                this.dispatch("Store.setPossesionData", data)
                return revoAccountNumber
            })
            .then((data)=> {
                fetch('https://eastern-amp-201517.appspot.com/revo/login?access_token='+token, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "paypoint_id": "1212235",
                        "account_number": "8349S"
                    })
                })
                .then((response)=>{
                    return response.json()
                })
                .then((content)=>{
                    this.dispatch("Store.setRevoToken", content)
                })
                .catch(function(error) {
                    Notify.error(error.message || error);
                    console.error(error);
                });
            })
    }
    
    getTransactions(topsOwnerID){
        GET('transactions/?filter[where][topsownerid]='+topsOwnerID+'&filter[order]=sequencenumber DESC')
            .then((transactionData)=>{
                this.dispatch("Store.setTransactions", transactionData)
            })
            .catch(function(error) {
                Notify.error(error.message || error);
                console.error(error);
            });
    }
    
    logout(history) {
        Session.actions.logout();
        
    }
    
    resetCurrentPassword (token,oldPassword,newPassword){
        var params = {
            oldPassword: oldPassword,
            newPassword: newPassword
        };
        
        var formData = new FormData();
        
        for (var k in params) {
            formData.append(k, params[k]);
        }
        
        var request = {
            method: 'POST',
            headers: {
                'Accept': 'text/html',
                'Content-Type': 'application/x-www-form-urlencoded',
            }, 'redirect' : 'error',
            body: 'oldPassword='+oldPassword+'&newPassword='+newPassword+''
        };
        
       fetch('https://eastern-amp-201517.appspot.com/api/users/change-password?access_token='+token, request)
        .then((response)=>{
            if(response.ok) {
                alert("Password Changed Successfully")
                return
            }
        throw new Error('Failed request. Please check your password and try again.');
        })
        .catch(function(error) {
          // Notify.error(error.message || error);
           alert("Incorrect Password. Please try again");
           return
        });
    }
  
    resetPassword(email) {
        fetch('https://eastern-amp-201517.appspot.com/request-password-reset', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
               "email": email
            })
        })
        .then((response)=>{
            return response.json()
        })
        .catch(function(error) {
            Notify.error(error.message || error);
            console.error(error);
            return error.json()
        });
        
    }
    
    submitArchitectural(token, data) {
        fetch('https://prod-00.westus.logic.azure.com:443/workflows/28153c1f9b924de391fcdf093e7f2878/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=aVWZr6jrCmtWZmOHWa8HpxIbaHiZETkYt-1iTvdWo_o', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
	           "ticketNumber": "789",
                "dateRequested": "9/13/18",
                "associationName": "Vintage Estates",
                "ownerName": "Skarley", 
                "address": "10400",
                "address2": "n/a",
                "city": "Miami",
                "state": "FL",
                "zip": "33165",
                "email": "sg@gmail.com",
                "phone": "3055195481",
                "startDate": "9/14/2018", 
                "numberOfDays": "30",
                "modificationRequested": "Change front doornp",
                "CompanyName": "coname",
                "contactName": "coperson",
                "companyAddress": "co add",
                "companyAddress2": "co add2",
                "companyCity": "co city",
                "companyState": "costate",
                "companyZip": "cozip",
                "companyEmail": "coemail",
                "companyPhone": "coPhone"
            })
        })
        .then((response)=>{
            return response.json()
        })
        .catch(function(error) {
            Notify.error(error.message || error);
            console.error(error);
            return error.json()
        });
    }

    updateStore(update) {
        this.dispatch("Store.updateState", update)
    }
    
    uploadFiles(files){
        files.forEach((file) => {
            var formData = new FormData();
            formData.append('file', file);
            UPLOAD('Containers/ams-file-storage/upload',formData)
            .then((resp)=>{
                Notify.success("File uploaded: "+resp.result.files.file[0].name);
                //this.dinpm run buildspatch("Store.setUserFiles", contactData)
            })
            .catch(function(error) {
                Notify.error(error.message || error);
                console.error(error);
            });
        });
    }
}

var actions = new Actions();
export default actions;