import Actions from "../actions/Actions.jsx";
import React from "react";
import { Link, Redirect }
from "react-router-dom";
import Flux from "@4geeksacademy/react-flux-dash";
import FontAwesome from 'react-fontawesome';
import Footer from '../components/Footer.jsx';
import {Session} from '../actions/utility/session';
import Navbar from '../components/Navbar.jsx';
import Store from "../store/Store.jsx";

export default class Profile extends Flux.View {
  constructor(props) {
    super(props);
    this.state = {
      changes:false,
      email:'',
      cell:'',
      house:'',
      business:'',
      password:{
        oldPassword: '',
        newPassword: ''
      },
      petInformation: {
        name: '',
        breed: '',
        color: '',
        weight: ''
      },
      address:'',
      address2:'',
      city:'',
      state:'',
      zip:'',
      userInformation: {
        firstName:'',
        lastName:'',
      },
      vehicleInformation: {
        make: '',
        model: '',
        color: '',
        year: '',
        tagNumber: ''
      }
    };
  }
 
 componentWillMount(){
    const user = Session.store.getSession()
    Actions.getEmail(user)
    const userInfo = Session.store.getSession().user.info
    Actions.getContactData(userInfo.peoplekey)
    Actions.getPropertyAddress(userInfo.accountnumber)
    this.setState ({ 
      userInformation: {
        firstName: userInfo.firstname,
        lastName: userInfo.lastname
      }
    });
    console.log(this.state.userInformation);
    console.log("here")
    this.bindStore(Store, () => {
      const propAdd = Store.getPropertyAddress()
      this.setState ({
        address:propAdd.address,
        address2:propAdd.address2,
        city:propAdd.city,
        state:propAdd.state,
        zip:propAdd.zip
      })
    })
    this.bindStore(Store, () => {
      const contactData = Store.getContactData()
      this.setState ({
        email:contactData.email,
        cell:contactData.Home,
        house:contactData.Alternate,
        business:contactData.Work
      })
    })
    this.bindStore(Store, () => {
      const email = Store.getEmail()
      this.setState ({
        email:email
      })
    })
  }
  handleInputChange({target}){
    let oldData = this.state
    let newData = ({
      changes:true,
      [target.id]: target.value
    })
  this.setState ({
    data: Object.assign(oldData, newData)
  });
  console.log("working")
  }
  handleSave(){
    let data = this.state
    Actions.save(data)
  }
 
  resetPassword(event){
    event.preventDefault()
    const sessionToken = Session.store.getSession().access_token;
    let oldPassword = this.state.oldPassword
    let newPassword = this.state.newPassword
    Actions.resetCurrentPassword(sessionToken,oldPassword,newPassword)
    this.setState ({
      oldPassword: '',
      newPassword: ''
    })
  }
  
  render() {
    let saveButton = (this.state.changes == true) ? (
      <div className="editable">
        <button className="btn btn-primary button-color" onClick={this.handleSave.bind(this)}>Save</button>
      </div>) : (
      <div className="editable">
        <button className="btn btn-primary button-color" disabled>Save</button>
      </div>
    )
    
    return (
      <div>         
        <Navbar active="profile"/>
        <div id="body">
          <span id="main" className="smallProfile">
            <div className="card">
              <div className="margin-style-user-back-btn">
                <Link to="/home"> 
                    <button type="button" className="btn btn-primary button-color">
                      <FontAwesome name='arrow-left' size="lg"/>
                    </button>
                </Link> 
              </div>
              <div className="card div-profile text-center"><h1>Profile</h1></div>
              <div className="margin-span-style">
                <ul className="menu-items">
                  <Link to="/user-information"> 
                    <li className="menu-items"><span className="parent"> User Information <span className="float-right"><FontAwesome name='chevron-right' size="lg"/></span></span></li>
                  </Link>
                 <Link to="/login-update"> 
                    <li className="menu-items"><span className="parent"> Change Password <span className="float-right"><FontAwesome name='chevron-right' size="lg"/></span></span></li>
                  </Link>
                  {/*<Link to="/pets">   
                    <li className="menu-items"><span className="parent"> Pets <span className="float-right"><FontAwesome name='chevron-right' size="lg"/></span></span></li>
                  </Link>  
                  <Link to="/vehicles"> 
                    <li className="menu-items"><span className="parent"> Vehicles <span className="float-right"><FontAwesome name='chevron-right' size="lg"/></span></span></li>
                  </Link>*/}
                  
                </ul>
              </div>
            </div>
          </span>
          
          <span className="largeProfile">
            <div id="enclosing">
              <div className="row d-flex justify-content-center max-width-profile">
                <div className="col-sm-3">
                  <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                      <a className="nav-link active" id="v-pills-information-tab" data-toggle="pill" href="#v-pills-information" role="tab" aria-controls="v-pills-information" aria-selected="true">User Information</a>
                      <a className="nav-link" id="v-pills-login-tab" data-toggle="pill" href="#v-pills-login" role="tab" aria-controls="v-pills-login" aria-selected="false">Change Password</a>
                      
                      {/*<a className="nav-link" id="v-pills-pets-tab" data-toggle="pill" href="#v-pills-pets" role="tab" aria-controls="v-pills-pets" aria-selected="false">Pets</a>
                      <a className="nav-link" id="v-pills-vehicles-tab" data-toggle="pill" href="#v-pills-vehicles" role="tab" aria-controls="v-pills-vehicles" aria-selected="false">Vehicles</a>*/}
                  </div>
                </div>
                <div id="contactPageCard" className="card col-sm-6">
                  <div className="tab-content" id="v-pills-tabContent">
                    <div className="tab-pane fade show active" id="v-pills-information" role="tabpanel" aria-labelledby="v-pills-information-tab">
                      <form id="paddingForm">
                        {/*<span>
                         <label className="boldify" htmlFor="title">Language</label>
                          <div className="btn-group profile-buttons-margin">
                            <button type="button" className="btn btn-primary dropdown-toggle button-color" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              English
                            </button>
                            {/*<div className="dropdown-menu">
                              <a className="dropdown-item" href="#">Spanish</a>
                            </div>
                          </div>
                        </span>*/}
                          <div className="form-row">
                            <div className="form-group col-sm-6">
                              <label htmlFor="inputAddress2" className="labelClass">Name</label>
                              <input type="text" className="form-control" value={this.state.userInformation.firstName} readonly/>
                            </div>
                            <div className="form-group col-sm-6" id="paddingInput">
                              <label htmlFor="inputAddress" className="labelClass"></label>
                              <input type="text" className="form-control" value={this.state.userInformation.lastName} />
                            </div>
                            <div className="form-group col-sm-6">
                              <label htmlFor="inputAddress" className="labelClass">Mailing address</label>
                              <input type="text" className="form-control" id="address" value={this.state.address} onChange={this.handleInputChange.bind(this)} />
                            </div>
                            <div className="form-group col-sm-6">
                              <label htmlFor="inputAddress" className="labelClass">Apt#</label>
                              <input type="text" className="form-control" id="address2" value={this.state.address2} onChange={this.handleInputChange.bind(this)}/>
                            </div>
                            <div className="form-row">
                            <div className="form-group col-sm-4">
                              <label htmlFor="inputCity" className="labelClass">City</label>
                              <input type="text" className="form-control" id="city" value={this.state.city} onChange={this.handleInputChange.bind(this)}/>
                            </div>
                            <div className="form-group col-sm-4">
                              <label htmlFor="inputState" className="labelClass">State</label>
                              <input type="text" className="form-control" id="state" value={this.state.state} onChange={this.handleInputChange.bind(this)}/>
                            </div>
                              <div className="form-group col-sm-4">
                                <label htmlFor="inputZip" className="labelClass">Zip</label>
                                <input type="text" className="form-control" id="zip" value={this.state.zip} onChange={this.handleInputChange.bind(this)}/>
                              </div>
                            </div>
                            <div className="form-group col-sm-6">
                              <label htmlFor="inputEmail4" className="labelClass">Email</label>
                              <input type="email" className="form-control" id="email" value={this.state.email} onChange={this.handleInputChange.bind(this)}/>
                            </div>
                            <div className="form-group col-sm-6">
                              <label htmlFor="inputAddress" className="labelClass">Cell Number</label>
                              <input type="text" className="form-control" id="cell" value={this.state.cell} onChange={this.handleInputChange.bind(this)}/>
                            </div>
                            <div className="form-group col-sm-6">
                              <label htmlFor="inputAddress" className="labelClass">House Number</label>
                              <input type="text" className="form-control" id="house" value={this.state.house} onChange={this.handleInputChange.bind(this)}/>
                            </div>
                            <div className="form-group col-sm-6">
                              <label htmlFor="inputAddress" className="labelClass">Business Number</label>
                              <input type="text" className="form-control" id="business" value={this.state.business} onChange={this.handleInputChange.bind(this)}/>
                            </div>
                          </div>
                          <div className="form-row">
                           
                           {/* <div>
                              <p className="boldify">&nbsp;Verified:&nbsp;</p>
                            </div>
                            <div>
                              <p readOnly>null</p>
                            </div>*/}
                          </div>
                         {saveButton}
                          
                          
                          
                        </form>
                        </div>
                        
                        <div className="tab-pane fade" id="v-pills-login" role="tabpanel" aria-labelledby="v-pills-login-tab">
                        <form id="paddingForm">
                          <div className="form-row">
                            <div className="form-group col-sm-6">
                              <label htmlFor="email" className="labelClass">Email</label>
                              <input type="text" className="form-control" value={this.state.email} />
                            </div>
                            <div className="form-group col-sm-6">
                            </div>
                            <div className="form-group col-sm-6">
                              <label htmlFor="oldPassword" className="labelClass">Old Password</label>
                              <input type="text" className="form-control" id="oldPassword" onChange={this.handleInputChange.bind(this)} />
                            </div>
                            <div className="form-group col-sm-6">
                            </div>
                            <div className="form-group col-sm-6">
                              <label htmlFor="newPassword" className="labelClass">New Password</label>
                              <input type="text" className="form-control" id="newPassword" onChange={this.handleInputChange.bind(this)} />
                            </div>
                          </div>
                          <div className="editable">
                            <button className="btn btn-primary" onClick={(event) =>{this.resetPassword(event)}}>Submit</button>
                          </div>
                        </form>
                        </div>
                        
                        
                        <div className="tab-pane fade" id="v-pills-pets" role="tabpanel" aria-labelledby="v-pills-pets-tab">
                        <form id="paddingForm">
                          <div className="form-row">
                            <div className="form-group col-sm-6">
                              <label htmlFor="inputAddress2" className="labelClass">Name</label>
                              <input type="text" className="form-control" value={this.state.petInformation.name} />
                            </div>
                            <div className="form-group col-sm-6">
                              <label htmlFor="inputAddress" className="labelClass">Breed</label>
                              <input type="text" className="form-control" value={this.state.petInformation.breed} />
                            </div>
                            <div className="form-group col-sm-6">
                              <label htmlFor="inputAddress" className="labelClass">Color</label>
                              <input type="text" className="form-control" id="inputAddress" value={this.state.petInformation.color} />
                            </div>
                            <div className="form-group col-sm-6">
                              <label htmlFor="inputAddress" className="labelClass">Weight</label>
                              <input type="text" className="form-control" id="inputAddress" value={this.state.petInformation.weight} />
                            </div>
                          </div>
                          <div className="editable">
                            <button className="btn btn-primary">Save</button>
                          </div>
                        </form>
                        </div>
                        <div className="tab-pane fade" id="v-pills-vehicles" role="tabpanel" aria-labelledby="v-pills-vehicles-tab">
                          <form id="paddingForm">
                            <div>
                              <div className="form-row">
                                <div className="form-group col-sm-6">
                                  <label htmlFor="inputAddress2" className="labelClass">Make</label>
                                  <input type="text" className="form-control" value={this.state.vehicleInformation.make} />
                                </div>
                                <div className="form-group col-sm-6">
                                  <label htmlFor="inputAddress" className="labelClass">Model</label>
                                  <input type="text" className="form-control" value={this.state.vehicleInformation.model} />
                                </div>
                                <div className="form-group col-sm-6">
                                  <label htmlFor="inputAddress" className="labelClass">Color</label>
                                  <input type="text" className="form-control" id="inputAddress" value={this.state.vehicleInformation.color} />
                                </div>
                                <div className="form-group col-sm-6">
                                  <label htmlFor="inputAddress" className="labelClass">Year</label>
                                  <input type="text" className="form-control" id="inputAddress" value={this.state.vehicleInformation.year} />
                                </div>
                                <div className="form-group col-sm-6">
                                  <label htmlFor="inputAddress" className="labelClass">Tag Number</label>
                                  <input type="text" className="form-control" id="inputAddress" value={this.state.vehicleInformation.tagNumber} />
                                </div>
                              </div>
                            </div>
                            {/*<div className="editable">
                              <button className="btn btn-primary">Save</button>
                            </div>*/}
                          </form>
                        </div>
                      <div className="tab-pane fade" id="v-pills-history" role="tabpanel" aria-labelledby="v-pills-history-tab">
                        <table className="table row-margin tableStyle">
                          <thead>
                            <tr>
                              <th scope="col">Date</th>
                              <th scope="col">Description</th>
                              <th scope="col">Location</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>April 30, 2018</td>
                              <td>Placeholder1</td>
                              <td>Casa Del Mar</td>
                            </tr>
                            <tr>
                              <td>April 26, 2018</td>
                              <td>Placeholder@1</td>
                              <td>Doral</td>
                            </tr>
                            <tr>
                              <td>April 19, 2018</td>
                              <td>Placeholder@g3</td>
                              <td>Coral Gables</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </span>
          </div>
          <Footer/>
      </div>
    );
  }
}
