import Flux from "@4geeksacademy/react-flux-dash";
import FontAwesome from 'react-fontawesome';
import Footer from '../components/Footer.jsx';
import Navbar from '../components/Navbar.jsx';
import React from "react";
import Store from "../store/Store.jsx";
import { Link, Redirect } from "react-router-dom";


export default class Documents extends Flux.View {

  constructor(props) {
    super(props);
    this.state = (
      Store.getLoggedInInfo()
    )
  }

  // Lifecycle methods

  componentWillMount() {
    this.setState(
      Store.getLoggedInInfo()
    )
  }

  // Views methods

  render() {
    
    
    return (
      <div>
        <Navbar active="documents"/>
        <div id="body">
          <span id="main" className="smallStatement">
          <div className="card">
            <div className="margin-style-user-back-btn">
              <Link to="/"> 
              <button type="button" className="btn btn-primary button-color">
                <FontAwesome name='arrow-left' size="lg"/>
              </button>
              </Link> 
            </div>
            <div className="card div-profile text-center">
              <h1> Documents </h1>
            </div>
            <div className="margin-span-style">
              <ul className="menu-items">
                <Link to="/notices"> 
                  <li className="menu-items"><span className="parent"> Notices <span className="float-right"><FontAwesome name='chevron-right' size="2x"/></span></span></li>
                </Link> 
                <Link to="/meetings">   
                  <li className="menu-items"><span className="parent"> Meetings <span className="float-right"><FontAwesome name='chevron-right' size="2x"/></span></span></li>
                </Link>
                <Link to="/financials">   
                  <li className="menu-items"><span className="parent"> Financials <span className="float-right"><FontAwesome name='chevron-right' size="2x"/></span></span></li>
                </Link>
                <Link to="/governing-documents"> 
                  <li className="menu-items"><span className="parent"> Governing Documents <span className="float-right"><FontAwesome name='chevron-right' size="2x"/></span></span></li>
                </Link> 
              </ul>
            </div>
          </div>
        </span>
        
        
        <span className ="largeStatement">
          <div id="enclosing">
            <div className="row max-width-statement">
              <div className="col-sm-5">
                <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  <a className="nav-link active" id="v-pills-notices-tab" data-toggle="pill" href="#v-pills-notices" role="tab" aria-controls="v-pills-notices" aria-selected="true">Notices</a>
                  <a className="nav-link" id="v-pills-meetings-tab" data-toggle="pill" href="#v-pills-meetings" role="tab" aria-controls="v-pills-meetings" aria-selected="false">Meetings</a>
                  <a className="nav-link" id="v-pills-financials-tab" data-toggle="pill" href="#v-pills-financials" role="tab" aria-controls="v-pills-financials" aria-selected="false">Financials</a>
                  <a className="nav-link" id="v-pills-documents-tab" data-toggle="pill" href="#v-pills-documents" role="tab" aria-controls="v-pills-documents" aria-selected="false">Governing Documents</a>
                </div>
              </div>
              
              <div id="contactPageCard" className="card col-sm-7">
                <div className="tab-content" id="v-pills-tabContent">
                
                  <div className="tab-pane fade show active" id="v-pills-notices" role="tabpanel" aria-labelledby="v-pills-notices-tab">
                    <table className="table table-hover">
                      <tbody>
                        <tr>
                          <a href="https://fs23.formsite.com/AFFINITY/files/f-6-0-10074145_qBvnK7FK_guide-to-hurricane-readiness.pdf" target="_blank">
                            <td className="no-bottom-border"><FontAwesome name='file-alt' size="2x"/></td>
                            <td className="no-bottom-border bold">2018 Hurricane Guide</td>
                          </a>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  
                  <div className="tab-pane fade" id="v-pills-meetings" role="tabpanel" aria-labelledby="v-pills-meetings-tab">
                    <table className="table table-hover">
                      <tbody>
                        <tr>
                          <a href="https://fs23.formsite.com/AFFINITY/files/f-6-0-10102161_dLTAl57b_2018_0830_Annual_Meeting_Agenda_01-09-18.pdf" target="_blank">
                            <td className="no-bottom-border"><FontAwesome name='file-alt' size="2x"/></td>
                            <td className="no-bottom-border bold">2018_0109 Annual Meeting</td>
                          </a>
                        </tr>
                        <tr>
                          <a href="https://fs23.formsite.com/AFFINITY/files/f-6-0-10102164_Ikvs3GXN_2018_0504_Meeting_Notice.pdf" target="_blank">
                            <td className="no-bottom-border"><FontAwesome name='file-alt' size="2x"/></td>
                            <td className="no-bottom-border bold">2018_0504 Board of Directors Meeting</td>
                          </a>
                        </tr>
                        <tr>
                          <a href="https://fs23.formsite.com/AFFINITY/files/f-33-2-10339312_Wc4GZydh_2018_1115_Notice_of_Proposed_2019_Budget_English__Spanish_-_Village_of_Palm_Bay.pdf" target="_blank">
                            <td className="no-bottom-border"><FontAwesome name='file-alt' size="2x"/></td>
                            <td className="no-bottom-border bold">2018_1129 Budget Meeting</td>
                          </a>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  
                  
                  <div className="tab-pane fade" id="v-pills-financials" role="tabpanel" aria-labelledby="v-pills-financials-tab">
                    <table className="table table-hover">
                      <tbody>
                        <tr>
                          <a href="https://fs23.formsite.com/AFFINITY/files/f-33-4-10339199_z6w3Kci6_2018_1130_Financial_Report_Website.pdf" target="_blank">
                            <td className="no-bottom-border"><FontAwesome name='file-alt' size="2x"/></td>
                            <td className="no-bottom-border bold">November Financial Report</td>
                          </a>
                        </tr>
                        <tr>
                          <a href="https://fs23.formsite.com/AFFINITY/files/f-33-3-10339199_8GuEvObw_2018_1031_Financial_Report_Website.pdf" target="_blank">
                            <td className="no-bottom-border"><FontAwesome name='file-alt' size="2x"/></td>
                            <td className="no-bottom-border bold">October Financial Report</td>
                          </a>
                        </tr>
                        <tr>
                          <a href="https://fs23.formsite.com/AFFINITY/files/f-33-2-10339199_GzcwCRQV_2018_0930_Financial_Report_Website.pdf" target="_blank">
                            <td className="no-bottom-border"><FontAwesome name='file-alt' size="2x"/></td>
                            <td className="no-bottom-border bold">September Financial Report</td>
                          </a>
                        </tr>
                        <tr>
                          <a href="https://fs23.formsite.com/AFFINITY/files/f-6-0-10138175_BTpRiNoa_2018_0831_Financial_Report_Village_of_Palm_Bay_website.pdf" target="_blank">
                            <td className="no-bottom-border"><FontAwesome name='file-alt' size="2x"/></td>
                            <td className="no-bottom-border bold">August Financial Report</td>
                          </a>
                        </tr>
                        <tr>
                          <a href="https://fs23.formsite.com/AFFINITY/files/f-6-0-10102082_Mj4NrBFZ_2018_0731_Financial_Report_Village_of_Palm_Bay_WEB.pdf" target="_blank">
                            <td className="no-bottom-border"><FontAwesome name='file-alt' size="2x"/></td>
                            <td className="no-bottom-border bold">July Financial Report</td>
                          </a>
                        </tr>
                        <tr>
                          <a href="https://fs23.formsite.com/AFFINITY/files/f-6-0-10102080_8GDtJJ0i_2018_0630_Financial_Report_Village_of_Palm_Bay_WEB.pdf" target="_blank">
                            <td className="no-bottom-border"><FontAwesome name='file-alt' size="2x"/></td>
                            <td className="no-bottom-border bold">June Financial Report</td>
                          </a>
                        </tr>
                        <tr>
                          <a href="https://fs23.formsite.com/AFFINITY/files/f-6-0-10102079_DxiZHvtM_2018_0531_Final_Financial_Report_Village_of_Palm_Bay_WEB.pdf" target="_blank">
                            <td className="no-bottom-border"><FontAwesome name='file-alt' size="2x"/></td>
                            <td className="no-bottom-border bold">May Financial Report</td>
                          </a>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  
                  
                  <div className="tab-pane fade" id="v-pills-documents" role="tabpanel" aria-labelledby="v-pills-documents-tab">
                    <table className="table table-hover">
                      <tbody>
                        <tr>
                          <a href="https://fs23.formsite.com/AFFINITY/files/f-6-0-10138195_k4dotghV_Declaration.pdf" target="_blank">
                            <td className="no-bottom-border"><FontAwesome name='file-alt' size="2x"/></td>
                            <td className="no-bottom-border bold">Declaration</td>
                         </a>
                        </tr>
                        <tr>
                          <a href="https://fs23.formsite.com/AFFINITY/files/f-6-0-10138199_Gvpxf8RL_Articles_Of_Incorporation.pdf" target="_blank">
                            <td className="no-bottom-border"><FontAwesome name='file-alt' size="2x"/></td>
                            <td className="no-bottom-border bold">Articles of Incorporation</td>
                         </a>
                        </tr>
                        <tr>
                          <a href="https://fs23.formsite.com/AFFINITY/files/f-6-0-10138200_oYH0de6w_ByLaws.pdf" target="_blank">
                            <td className="no-bottom-border"><FontAwesome name='file-alt' size="2x"/></td>
                            <td className="no-bottom-border bold">Bylaws</td>
                         </a>
                        </tr>
                        <tr>
                          <a href="https://fs23.formsite.com/AFFINITY/files/f-6-0-10138202_V4EqoGsL_Rules__Regulations.pdf" target="_blank">
                            <td className="no-bottom-border"><FontAwesome name='file-alt' size="2x"/></td>
                            <td className="no-bottom-border bold">Rules and Regulations</td>
                          </a>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </span>
        </div>
        <Footer/>
      </div>
    );
  }
}
