import Actions from "../../actions/Actions.jsx";
import React from "react";
import Store from "../../store/Store.jsx";

export default class Success extends React.Component {

    constructor(props) {
        super(props);
        this.state = (
            Store.getLoggedInInfo()
        )
    }

    // Lifecycle methods

    componentWillMount() {
        this.setState(
            Store.getLoggedInInfo()
        )
    }

    // Views methods

    buttonNav(page, event) {
        event.preventDefault()
        Actions.updateStore({
            registerActivePage: page
        })
    }

    render() {
        return (
            <div>
                <h1 id="logincard-title-style">Success!</h1>
                
                <div className="form-row">
                    <div className="form-group col-sm-8">
                        <p>Check your email. Log-in information was emailed to you.</p>
                    </div>
                    
                    <div className="form-group col-sm-8">
                        <button className="btn btn-primary" onClick={(event) =>{this.buttonNav("login",event)}}>Click here to log in</button>
                    </div>
                </div>
            </div>
        );
    }
}
