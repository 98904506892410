import Actions from "../actions/Actions.jsx";
import Flux from "@4geeksacademy/react-flux-dash";
import FontAwesome from 'react-fontawesome';
import Footer from '../components/Footer.jsx';
import Navbar from '../components/Navbar.jsx';
import React from "react";
import Store from "../store/Store.jsx";
import {Session} from '../actions/utility/session';
import { Link, Redirect } from "react-router-dom";
import bankDraft from "../../../src/img/upload_docs_pics/Bank_Draft.jpg";
import frontBack from "../../../src/img/upload_docs_pics/FrontBack.jpg";

export default class Dispute extends Flux.View {

    constructor(props) {
        super(props);
        this.state = (
            Store.getLoggedInInfo()
        )
        this.state = ({
            value: '',
            selectedPaymentType: '',
            multipleAssociations: '',
            inputFieldHasData: '',
            otherFieldHasData: '',
            reasonFieldHasData: '',
            buttonDisabled: false
        })
        this.bindStore(Store, () => {
            this.setState(
                Store.getLoggedInInfo()
            )
        })
    }

    // Lifecycle methods

    componentWillMount() {
        this.setState(
            Store.getLoggedInInfo()
        )
    }

    buttonNav(page, event) {
        event.preventDefault()
        Actions.updateStore({
            disputePage: page
        })
    }

    handleOtherInput(event) {
        this.setState({ otherFieldHasData: event.target.value })
    }

    handleReasonInput(event) {
        this.setState({ reasonFieldHasData: event.target.value })
    }

    handleButtonDisabled(event) {
        this.setState({ buttonDisabled: event.target.value })
    }

    handleChange(event) {
        this.setState({ inputFieldHasData: event.target.value })
    }

    handleClick(event) {
        this.setState({ value: event.target.value });
    }

    handleSelectedPaymentType(event) {
        this.setState({ selectedPaymentType: event.target.value })
    }

    handleDecisionChange(event) {
        this.setState({ multipleAssociations: event.target.value })
    }

    handleValidation(event) {
        this.setState({ isValidated: event.target.value })
    }

    isActive(value) {
        return 'col-sm-8 maximum-width ' + ((value === this.state.disputePage) ? '' : 'd-none')
    }
    
    
    formSubmit(page, event){
        
        event.preventDefault()
        
        const sessionToken = Session.store.getSession().access_token;
        
        const data = {
          id:"",
          topsownerid:"123467",
          diputereason: this.state.value, 
          paymentsmadeby: this.state.selectedPaymentType,
          payingmultipleunits: this.state.multipleAssociations,
          payingunits: this.state.inputFieldHasData,  
          reason: this.state.reasonFieldHasData,  
          other: this.state.otherFieldHasData
        }
        
        //const formResults = Actions.submitDispute(sessionToken, data)
        
        Actions.updateStore({
          disputePage: page
        })
    }

    render() {

        // Rendering for information page
        const selectOption = this.state.value;

        const yesOrNo = this.state.multipleAssociations;

        let divZero, divOne, divTwo, divThree, divFour, divFive;
        divZero = divOne = divTwo = divThree = divFour = divFive = ' ';

        if (selectOption === '' || selectOption === 'returned' || selectOption ===
            'late fee' || selectOption === 'charged' || selectOption === 'balance') {
            divOne = divTwo = divThree = divFour = divFive = ' d-none';
        }
        else if (selectOption === 'not posted') {
            if (yesOrNo === 'yes') {
                divFour = divFive = ' d-none';
            }
            else {
                divThree = divFour = divFive = ' d-none';
            }
        }
        else if (selectOption === 'disagree') {
            divOne = divTwo = divThree = divFive = ' d-none';
        }
        else {
            divOne = divTwo = divThree = divFour = ' d-none';
        }

        // Rendering for document upload page
        const displayedPage = this.state.disputePage;

        const typeOfPayment = this.state.selectedPaymentType;

        let divZeroOne, divOneOne, divTwoOne, divThreeOne, divFourOne, divFiveOne;
        divZeroOne = divOneOne = divTwoOne = divThreeOne = divFourOne = divFiveOne = ' ';

        if (displayedPage === 'documentUpload') {
            if ((selectOption === 'not posted') && (typeOfPayment === 'auto' || typeOfPayment === 'bill')) {
                divOneOne = divTwoOne = divThreeOne = divFourOne = divFiveOne = ' d-none';
            }
            else if (selectOption === 'not posted' && typeOfPayment === 'check') {
                divZeroOne = divTwoOne = divThreeOne = divFourOne = divFiveOne = ' d-none';
            }
            else if (selectOption === 'not posted' && typeOfPayment === 'card') {
                divZeroOne = divOneOne = divThreeOne = divFourOne = divFiveOne = ' d-none';
            }
            else if (selectOption === 'returned' || selectOption === 'late fee' ||
                selectOption === 'disagree' || selectOption === 'other') {
                divZeroOne = divOneOne = divTwoOne = divThreeOne = divFourOne = ' d-none';
            }
            else if (selectOption === 'charged') {
                divZeroOne = divOneOne = divTwoOne = divFourOne = divFiveOne = ' d-none';
            }
            else {
                divZeroOne = divOneOne = divTwoOne = divThreeOne = divFiveOne = ' d-none';
            }
        }

        // Form validation
        let disabled = this.state.buttonDisabled;

        let input = this.state.inputFieldHasData;

        let other = this.state.otherFieldHasData;

        let reason = this.state.reasonFieldHasData;

        if (displayedPage === 'information') {
            if (selectOption === '') {
                disabled = (!this.state.buttonDisabled);
            }
            else if (selectOption === 'not posted') {
                disabled = (!this.state.buttonDisabled);
                if (typeOfPayment === 'auto' || typeOfPayment === 'bill' ||
                    typeOfPayment === 'check' || typeOfPayment === 'card') {
                    if (yesOrNo === 'no') {
                        disabled = this.state.buttonDisabled
                    }
                    else if (yesOrNo === 'yes') {
                        if (input !== '') {
                            disabled = this.state.buttonDisabled;
                        }
                    }
                    else {
                        disabled = (!this.state.buttonDisabled)
                    }
                }
            }
            else if (selectOption === 'disagree' || selectOption === 'other') {
                disabled = (!this.state.buttonDisabled)
                if (other !== '' || reason !== '') {
                    disabled = this.state.buttonDisabled;
                }
            }
            else {
                disabled = this.state.buttonDisabled;
            }
        }


        return (
            <div>
            <Navbar active="dispute"/>
            <div className="largeDispute">
                <div className={this.isActive("disputeForm") + " section-height"}>
                    <div className="div-bottom-padding">
                        <h1 className="text-center">Account Dispute</h1>
                        <p>If you have found any discrepancies with your unit owner account statement, 
                        please complete this form and provide your supporting documentation.</p>
                        <p>Before you begin please make sure you have the applicable supporting documentation. 
                        These will vary depending on your situation but you will not be able to submit this form unless they are provided.</p>
                        <h3>Documentation:</h3>
                        <ul>
                            <li>Copies of the front and back of your check</li>
                            <li>Bank statement showing ACH Withdrawl</li>
                            <li>Credit card statement showing withdraw</li>
                            <li>Copy of property title or deed</li>
                            <li>Closing documents proving sale of the property</li>
                        </ul>
                        <p>
                            Once submitted, we will immediately provide you with a reference number 
                            and confirmation via email that your request was recieved. We will then 
                            review your request and respond letting you know if we have the information 
                            we need to proceed. If we don't we will request it at that time. 
                            When everything is completed correctly <strong><em>your dispute will be processed within 15 days.</em></strong> 
                        </p>
                        <p>
                            Please rest assured that during this time your account will be placed on hold so no further action will be taken.
                        </p>
                        
                        <p>
                            Thank you!<br />
                            Accounts Receiveables Department<br />
                            Affinity Management Services
                        </p>
                       
                        <div className="row d-flex justify-content-end" id="button-padding-right">
                            <button className="btn btn-primary button-color" onClick={(event) =>{this.buttonNav("information",event)}}>Next&gt;</button>
                        </div>
                    </div>
                </div>
                
                <div className={this.isActive("information") + " section-height"}>
                    <div className="container h-100 div-bottom-padding">
                        <h1 className="text-center">Information</h1>
                            <form className="row h-100 justify-content-center align-items-center">
                            
                              <div className={"form-group col-sm-9" + divZero}>
                                <label><strong>Reason for Dispute<span className="asterisk">*</span></strong></label>
                                <select className="form-control" value={this.state.value} onChange={this.handleClick.bind(this)} required>
                                  <option value='' >Select from dropdown menu</option>
                                  <option value='not posted'>My payment is not posted on my account</option>
                                  <option value='returned'>My payment was returned back to me</option>
                                  <option value='late fee'> I was charged a late fee</option>
                                  <option value='disagree'>I disagree with the pending balance</option>
                                  <option value='charged'>I sold my property and I am still being charged</option>
                                  <option value='balance'>I just bought this property and the account has a balance</option>
                                  <option value='other'>Other</option>
                                </select>
                              </div>
                                  
                            <div className={"form-group col-sm-10" + divOne}>
                                <label htmlFor="paymentType"><strong>My Payments were made by:<span className="asterisk">*</span></strong></label>
                                <div className="row d-flex justify-content-between col-sm-8" required>
                                  <div className="radio">
                                    <label>
                                      <input type="radio" value="auto" checked={this.state.selectedPaymentType === 'auto'} onChange={this.handleSelectedPaymentType.bind(this)} />
                                      Auto Pay (ACH)
                                    </label>
                                  </div>
                                  <div className="radio">
                                    <label>
                                      <input type="radio" value="bill" checked={this.state.selectedPaymentType === 'bill'} onChange={this.handleSelectedPaymentType.bind(this)}/>
                                      Billpay 
                                    </label>
                                  </div>
                                  <div className="radio">
                                    <label>
                                      <input type="radio" value="check" checked={this.state.selectedPaymentType === 'check'} onChange={this.handleSelectedPaymentType.bind(this)}/>
                                      Check
                                    </label>
                                  </div>
                                  <div className="radio">
                                    <label>
                                      <input type="radio" value="card" checked={this.state.selectedPaymentType === 'card'} onChange={this.handleSelectedPaymentType.bind(this)}/>
                                      Credit Card
                                    </label>
                                  </div>
                                </div>
                            </div>
                                
                            <div className={"form-group col-sm-10" + divTwo}>
                                <label htmlFor="multipleAssociations"><strong>I'm paying for multiple units in the association.<span className="asterisk">*</span></strong></label>
                                <div className="row d-flex justify-content-between col-sm-7">
                                  <div className="radio">
                                    <label>
                                      <input type="radio" value="yes" checked={this.state.multipleAssociations === 'yes'} onChange={this.handleDecisionChange.bind(this)} />
                                      Yes
                                    </label>
                                  </div>
                                  <div className="radio">
                                    <label>
                                      <input type="radio" value="no" checked={this.state.multipleAssociations === 'no'} onChange={this.handleDecisionChange.bind(this)}/>
                                      No 
                                    </label>
                                  </div>
                                </div>
                                
                                <div className={divThree}>
                                    <label htmlFor="unitNumber"><strong>Please list the unit# or address separated by commas. ie (417, 123 main st)<span className="asterisk">*</span></strong></label>
                                    <input className="form-control" type="text" value={this.state.inputFieldHasData} onChange={this.handleChange.bind(this)} required/>
                                </div>
                            </div>
                            
                            <div className={"form-group col-sm-10" + divFour}>
                              <div className="form-group">
                                <label htmlFor="reason"><strong>Reason<span className="asterisk">*</span></strong></label>
                                <textarea className="form-control" id="reason" rows="5" value={this.state.reasonFieldHasData} onChange={this.handleReasonInput.bind(this)} 
                                placeholder="Please provide us with as much detail as you can. This will help us process your request faster." required></textarea>
                              </div>
                            </div>
                       
                            <div className={"form-group col-sm-10" + divFive}>
                              <div className="form-group">
                                <label htmlFor="other"><strong>Other<span className="asterisk">*</span></strong></label>
                                <textarea className="form-control" id="other" rows="5" value={this.state.otherFieldHasData} onChange={this.handleOtherInput.bind(this)} 
                                placeholder="Please provide us with as much detail as you can. This will help us process your request faster." required></textarea>
                              </div>
                            </div>
                           
                            <div className="row d-flex justify-content-between col-sm-10">
                                <button className="btn btn-primary button-color" onClick={(event) =>{this.buttonNav("disputeForm",event)}}>&lt;Previous</button>
                                <button className="btn btn-primary button-color" disabled={disabled} onClick={(event) =>{this.buttonNav("documentUpload",event)}}>Next&gt;</button>
                            </div>
                        </form>
                    </div>
                </div>
                
                <div className={this.isActive("documentUpload") + " section-height"}>
                    <div className="div-bottom-padding">
                        <h1 className="text-center">Document Upload</h1>
                        
                        <p className="text-center"><strong>Please upload your supporting documentation</strong></p>
                        
                        <div className={"visible" + divZeroOne}>
                            <form>
                              <div className="form-group">
                                <label><strong>Bank Withdrawal</strong></label>
                                <input type="file" className="form-control-file col-sm-4 card inputCard" />
                              </div>
                            </form>
                            <h3 className="text-center">Example</h3>
                            <div className="d-flex justify-content-center col-sm-12">
                                <img src={bankDraft} className="img-fluid" alt="Bank withdrawal image" />
                            </div>
                        </div>
                        
                        <div className={"visible" + divOneOne}>
                            <form className="d-flex justify-content-between">
                              <div className="form-group">
                                <label><strong>Front of Check</strong></label>
                                <input type="file" className="form-control-file card inputCard" />
                              </div>
                              <div className="form-group">
                                <label><strong>Back of Check</strong></label>
                                <input type="file" className="form-control-file card inputCard" />
                              </div>
                            </form>
                            <h3 className="text-center">Example</h3>
                            <p>If a payment is not reflecting on your account <strong><em>you must provide a copy of both the 
                            front and back of the check (s), bill pay screenshots do not provide sufficient information
                            for us to resolve these issues therefore they will not be accepted.</em></strong></p>
                            <div className="d-flex justify-content-center col-sm-12">
                                <img src={frontBack} className="img-fluid" alt="Check front and back image" />
                            </div>
                        </div>
                        
                        <div className={"visible" + divTwoOne}>
                            <form>
                              <div className="form-group">
                                <label><strong>Credit Card Statement:</strong></label>
                                <input type="file" className="form-control-file col-sm-4 card inputCard" />
                              </div>
                            </form>
                        </div>
                        
                        <div className={"visible" + divThreeOne}>
                            <form className="d-flex justify-content-between">
                              <div className="form-group">
                                <label><strong>Closing Documents</strong></label>
                                <input type="file" className="form-control-file card inputCard" />
                              </div>
                              <div className="form-group">
                                <label><strong>HUD Statement</strong></label>
                                <input type="file" className="form-control-file card inputCard" />
                              </div>
                            </form>
                        </div>
                        
                        <div className={"visible" + divFourOne}>
                            <form>
                              <div className="form-group">
                                <label><strong>Warranty DEED</strong></label>
                                <input type="file" className="form-control-file col-sm-4 card inputCard" />
                              </div>
                            </form>
                        </div>
                        
                        <div className={"visible" + divFiveOne}>
                            <form>
                              <div className="form-group">
                                <label><strong>Upload any document that may support your request</strong></label>
                                <input type="file" className="form-control-file col-sm-4 card inputCard" />
                              </div>
                            </form>
                        </div>
                        
                        <div className="row d-flex justify-content-between col-sm-12">
                            <button className="btn btn-primary button-color" onClick={(event) =>{this.buttonNav("information",event)}}>&lt;Previous</button>
                            <button className="btn btn-primary button-color" onClick={(event) =>{this.buttonNav("nextSteps",event)}}>Next&gt;</button>
                        </div>
                    </div>
                </div>
                
                <div className={this.isActive("nextSteps") + " section-height"}>
                    <div className="div-bottom-padding">
                        <h1 className="text-center">Next Steps...</h1>
                        <p>Thank you for taking the time to provide us with the information.</p>
                        <p>Here are the next steps:</p>
                        <ul>
                            <li>You will recieve a confirmation email letting you know we recieved your request</li>
                            <li>We will review your request to ensure we have everything we need to process 
                            it and we'll email to let you know that it is complete or to ask for additional information</li>
                            <li> Within 15 days we will work process your request and take the necessary action to resolve the
                            matter and will email you with a response</li>
                        </ul>
                        <p>
                        Thank you! <br />
                        Accounts Receiveables Department <br />
                        Affinity Management Services
                        </p>
                        <div className="row d-flex justify-content-between col-sm-12">
                            <button className="btn btn-primary button-color" onClick={(event) =>{this.buttonNav("documentUpload",event)}}>&lt;Previous</button>
                            <button className="btn btn-primary button-color" onClick={(event) =>{this.formSubmit("thankYou", event)}}>Submit</button>
                        </div>
                    </div>
                </div>
                
                <div className={this.isActive("thankYou") + " section-height"}>
                <div className="container h-100 div-bottom-padding">
                  <h1 id="logincard-title-style" className="text-center">Thank you!</h1>
                  <div className="row h-100 justify-content-center align-items-center">
                      <div className="form-group col-sm-8">
                          <p className="text-center">For submitting your dispute request.<br />
                          We will get back to you shortly.</p>
                      </div>
                      <div className="form-group col-sm-8 d-flex justify-content-center">
                          <a href="/"><button className="btn btn-primary button-color">Return to Home Page</button></a>
                      </div>
                  </div>
                  </div>
                </div>
                
                </div>
                
                {/*Small Dispute*/}
                <div className="smallDispute">
                
                <div className={this.isActive("disputeForm") + " section-height"}>
                    <div className="div-bottom-padding">
                        <h1 className="text-center">Account Dispute</h1>
                        <p>If you have found any discrepancies with your unit owner account statement, 
                        please complete this form and provide your supporting documentation.</p>
                        <p>Before you begin please make sure you have the applicable supporting documentation. 
                        These will vary depending on your situation but you will not be able to submit this form unless they are provided.</p>
                        <h5>Documentation:</h5>
                        <ul>
                            <li>Copies of the front and back of your check</li>
                            <li>Bank statement showing ACH Withdrawl</li>
                            <li>Credit card statement showing withdraw</li>
                            <li>Copy of property title or deed</li>
                            <li>Closing documents proving sale of the property</li>
                        </ul>
                        <p>
                            Once submitted, we will immediately provide you with a reference number 
                            and confirmation via email that your request was recieved. We will then 
                            review your request and respond letting you know if we have the information 
                            we need to proceed. If we don't we will request it at that time. 
                            When everything is completed correctly <strong><em>your dispute will be processed within 15 days.</em></strong> 
                        </p>
                        <p>
                            Please rest assured that during this time your account will be placed on hold so no further action will be taken.
                        </p>
                        
                        <p>
                            Thank you!<br />
                            Accounts Receiveables Department<br />
                            Affinity Management Services
                        </p>
                       
                        <div className="row d-flex justify-content-end" id="button-padding-right">
                            <button className="btn btn-primary button-color" onClick={(event) =>{this.buttonNav("information",event)}}>Next&gt;</button>
                        </div>
                    </div>
                </div>
                
                <div className={this.isActive("information") + " section-height"}>
                    <div className="container h-100 div-bottom-padding">
                        <h1 className="text-center">Information</h1>
                            <form className="row h-100 justify-content-center align-items-center">
                            
                              <div className={"form-group col-sm-10" + divZero}>
                                <label><strong>Reason for Dispute<span className="asterisk">*</span></strong></label>
                                <select className="form-control" value={this.state.value} onChange={this.handleClick.bind(this)} required>
                                  <option value='' >Select from dropdown menu</option>
                                  <option value='not posted'>My payment is not posted on my account</option>
                                  <option value='returned'>My payment was returned back to me</option>
                                  <option value='late fee'> I was charged a late fee</option>
                                  <option value='disagree'>I disagree with the pending balance</option>
                                  <option value='charged'>I sold my property and I am still being charged</option>
                                  <option value='balance'>I just bought this property and the account has a balance</option>
                                  <option value='other'>Other</option>
                                </select>
                              </div>
                                  
                            <div className={"form-group col-sm-10" + divOne}>
                                <label htmlFor="paymentType"><strong>My Payments were made by:<span className="asterisk">*</span></strong></label>
                                <div className="row d-flex justify-content-between col-sm-8" required>
                                  <div className="radio">
                                    <label>
                                      <input type="radio" value="auto" checked={this.state.selectedPaymentType === 'auto'} onChange={this.handleSelectedPaymentType.bind(this)} />
                                      Auto Pay (ACH)
                                    </label>
                                  </div>
                                  <div className="radio">
                                    <label>
                                      <input type="radio" value="bill" checked={this.state.selectedPaymentType === 'bill'} onChange={this.handleSelectedPaymentType.bind(this)}/>
                                      Billpay 
                                    </label>
                                  </div>
                                  <div className="radio">
                                    <label>
                                      <input type="radio" value="check" checked={this.state.selectedPaymentType === 'check'} onChange={this.handleSelectedPaymentType.bind(this)}/>
                                      Check
                                    </label>
                                  </div>
                                  <div className="radio">
                                    <label>
                                      <input type="radio" value="card" checked={this.state.selectedPaymentType === 'card'} onChange={this.handleSelectedPaymentType.bind(this)}/>
                                      Credit Card
                                    </label>
                                  </div>
                                </div>
                            </div>
                                
                            <div className={"form-group col-sm-10" + divTwo}>
                                <label htmlFor="multipleAssociations"><strong>I'm paying for multiple units in the association.<span className="asterisk">*</span></strong></label>
                                <div className="row d-flex justify-content-between col-sm-7">
                                  <div className="radio">
                                    <label>
                                      <input type="radio" value="yes" checked={this.state.multipleAssociations === 'yes'} onChange={this.handleDecisionChange.bind(this)} />
                                      Yes
                                    </label>
                                  </div>
                                  <div className="radio">
                                    <label>
                                      <input type="radio" value="no" checked={this.state.multipleAssociations === 'no'} onChange={this.handleDecisionChange.bind(this)}/>
                                      No 
                                    </label>
                                  </div>
                                </div>
                                
                                <div className={divThree}>
                                    <label htmlFor="unitNumber"><strong>Please list the unit# or address separated by commas. ie (417, 123 main st)<span className="asterisk">*</span></strong></label>
                                    <input className="form-control" type="text" value={this.state.inputFieldHasData} onChange={this.handleChange.bind(this)} required/>
                                </div>
                            </div>
                            
                            <div className={"form-group col-sm-10" + divFour}>
                              <div className="form-group">
                                <label htmlFor="reason"><strong>Reason<span className="asterisk">*</span></strong></label>
                                <textarea className="form-control" id="reason" rows="5" value={this.state.reasonFieldHasData} onChange={this.handleReasonInput.bind(this)} 
                                placeholder="Please provide us with as much detail as you can. This will help us process your request faster." required></textarea>
                              </div>
                            </div>
                       
                            <div className={"form-group col-sm-10" + divFive}>
                              <div className="form-group">
                                <label htmlFor="other"><strong>Other<span className="asterisk">*</span></strong></label>
                                <textarea className="form-control" id="other" rows="5" value={this.state.otherFieldHasData} onChange={this.handleOtherInput.bind(this)} 
                                placeholder="Please provide us with as much detail as you can. This will help us process your request faster." required></textarea>
                              </div>
                            </div>
                           
                            <div className="row d-flex justify-content-between col-sm-10">
                                <button className="btn btn-primary button-color" onClick={(event) =>{this.buttonNav("disputeForm",event)}}>&lt;Previous</button>
                                <button className="btn btn-primary button-color" disabled={disabled} onClick={(event) =>{this.buttonNav("documentUpload",event)}}>Next&gt;</button>
                            </div>
                        </form>
                    </div>
                </div>
                
                <div className={this.isActive("documentUpload") + " section-height"}>
                    <div className="div-bottom-padding">
                        <h1 className="text-center">Document Upload</h1>
                        
                        <p className="text-center"><strong>Please upload your supporting documentation</strong></p>
                        
                        <div className={"visible" + divZeroOne}>
                            <form>
                              <div className="form-group">
                                <label><strong>Bank Withdrawal</strong></label>
                                <input type="file" className="form-control-file col-sm-4 card inputCard" />
                              </div>
                            </form>
                            <h5 className="text-center">Example</h5>
                            <br />
                            <div className="d-flex justify-content-center col-sm-12">
                                <img src={bankDraft} className="img-fluid img-fluid-mobile-bill" alt="Bank withdrawal image" />
                            </div>
                            <br />
                        </div>
                        
                        <div className={"visible" + divOneOne}>
                            <form>
                              <div className="form-group">
                                <label><strong>Front of Check</strong></label>
                                <input type="file" className="form-control-file card inputCard" />
                              </div>
                              <div className="form-group">
                                <label><strong>Back of Check</strong></label>
                                <input type="file" className="form-control-file card inputCard" />
                              </div>
                            </form>
                            <h5 className="text-center">Example</h5>
                            <p>If a payment is not reflecting on your account <strong><em>you must provide a copy of both the 
                            front and back of the check (s), bill pay screenshots do not provide sufficient information
                            for us to resolve these issues therefore they will not be accepted.</em></strong></p>
                            <br />
                            <div className="d-flex justify-content-center col-sm-12">
                                <img src={frontBack} className="img-fluid img-fluid-mobile" alt="Check front and back image" />
                            </div>
                            <br />
                        </div>
                        
                        <div className={"visible" + divTwoOne}>
                            <form>
                              <div className="form-group">
                                <label><strong>Credit Card Statement:</strong></label>
                                <input type="file" className="form-control-file col-sm-4 card inputCard" />
                              </div>
                            </form>
                        </div>
                        
                        <div className={"visible" + divThreeOne}>
                            <form>
                              <div className="form-group">
                                <label><strong>Closing Documents</strong></label>
                                <input type="file" className="form-control-file card inputCard" />
                              </div>
                              <div className="form-group">
                                <label><strong>HUD Statement</strong></label>
                                <input type="file" className="form-control-file card inputCard" />
                              </div>
                            </form>
                        </div>
                        
                        <div className={"visible" + divFourOne}>
                            <form>
                              <div className="form-group">
                                <label><strong>Warranty DEED</strong></label>
                                <input type="file" className="form-control-file col-sm-4 card inputCard" />
                              </div>
                            </form>
                        </div>
                        
                        <div className={"visible" + divFiveOne}>
                            <form>
                              <div className="form-group">
                                <label><strong>Upload any document that may support your request</strong></label>
                                <input type="file" className="form-control-file col-sm-4 card inputCard" />
                              </div>
                            </form>
                        </div>
                        
                        <div className="row d-flex justify-content-between col-sm-12 row-mobile">
                            <button className="btn btn-primary button-color" onClick={(event) =>{this.buttonNav("information",event)}}>&lt;Previous</button>
                            <button className="btn btn-primary button-color" onClick={(event) =>{this.buttonNav("nextSteps",event)}}>Next&gt;</button>
                        </div>
                    </div>
                </div>
                
                <div className={this.isActive("nextSteps") + " section-height"}>
                    <div className="div-bottom-padding">
                        <h1 className="text-center">Next Steps...</h1>
                        <p>Thank you for taking the time to provide us with the information.</p>
                        <p>Here are the next steps:</p>
                        <ul>
                            <li>We will review your request to ensure we have everything we need to process 
                            it and we'll email to let you know that it is complete or to ask for additional information</li>
                            <li> Within 15 days we will work process your request and take the necessary action to resolve the
                            matter and will email you with a response</li>
                        </ul>
                        <p>
                        Thank you! <br />
                        Accounts Receiveables Department <br />
                        Affinity Management Services
                        </p>
                        <div className="row d-flex justify-content-between col-sm-12 row-mobile">
                            <button className="btn btn-primary button-color" onClick={(event) =>{this.buttonNav("documentUpload",event)}}>&lt;Previous</button>
                            <button className="btn btn-primary button-color" onClick={(event) =>{this.formSubmit("thankYou", event)}}>Submit</button>
                        </div>
                    </div>
                </div>
                
                <div className={this.isActive("thankYou") + " section-height"}>
                <div className="container h-100 div-bottom-padding">
                  <h1 id="logincard-title-style" className="text-center">Thank you</h1>
                  <div className="row h-100 justify-content-center align-items-center">
                      <div className="form-group col-sm-8">
                          <p className="text-center">For submitting your dispute request.<br />
                          We will get back to you shortly.</p>
                      </div>
                      <div className="form-group col-sm-8 d-flex justify-content-center">
                          <Link to="/"><button className="btn btn-primary button-color">Return to Home Page</button></Link>
                      </div>
                  </div>
                  </div>
                </div>
                
                </div>
            <Footer/>
          </div>
        );
    }
}
