import Actions from "../actions/Actions.jsx";
import Flux from "@4geeksacademy/react-flux-dash";
import Footer from '../components/Footer.jsx';
import FontAwesome from 'react-fontawesome';
import Navbar from '../components/Navbar.jsx';
import PlacesAutocomplete from 'react-places-autocomplete';
import React from "react";
import RequestAccess from '../components/registration/RequestAccess.jsx';
import SentEmail from "../components/registration/SentEmail.jsx";
import Store from "../store/Store.jsx";
import Success from "../components/registration/Success.jsx";
import ThankYou from "../components/registration/ThankYou.jsx";
import { Redirect } from "react-router-dom";

export default class Login extends Flux.View {
  constructor(props) {
    super(props);
    this.state = (
      Store.getLoggedInInfo()
    )
    this.state = ({
      selectedOption: "option1"
    })
    this.bindStore(Store, () => {
      this.setState(
        Store.getLoggedInInfo()
      )
    })
  }

  // Lifecycle methods

  componentDidMount() {

    if (this.state.uid != '' && this.props.match.params.nextP === "logout") { Actions.logout() }

    this.setState(
      Store.getLoggedInInfo()
    )
  }

  // Views Methods

  buttonNav(page, event) {
    event.preventDefault()
    Actions.updateStore({
      registerActivePage: page,
      registerActiveMobilePage: page
    })
  }

  handleInputChange({ target }) {
    this.setState({
      [target.name]: target.value
    })
  console.log("login change email")
  }

  handleOptionChange(event) {
    this.setState({
      selectedOption: event.target.value
    })
  }

  handleFormSubmit(event) {

    // Writing choice to firebase is not implemented yet

    event.preventDefault()

    const option = this.state.selectedOption
    if (option === "option3") {
      Actions.updateStore({
        registerActivePage: "requestAccess",
        registerActiveMobilePage: "requestAccessMobile"
      })
    }
    else {
      Actions.updateStore({
        registerActivePage: "success",
        registerActiveMobilePage: "successMobile"
      })
    }
  }

  handleChangeUnit(event) {
    this.setState({ unitNumber: event.target.value });
  }

  handleChangeAddress(event) {
    this.setState({ address: event });
  }

  isActive(value) {
    return 'card col-sm-8 maximum-width ' + ((value === this.state.registerActivePage) ? '' : 'd-none');
  }

  isActiveResponsive(value) {
    return 'card col-sm-8 ' + ((value === this.state.registerActiveMobilePage) ? '' : 'd-none');
  }

  lookupMaskedInfo() {
    let address = this.state.address
    let unit = this.state.unitNumber
    let accountNumber = Actions.generateAccountNumber(address, unit)
    let maskedInfo = new Array()
    maskedInfo = Actions.fetchMaskedInfo(accountNumber)
    this.setState({
      registerActivePage: "maskedInfo",
      registerActiveMobilePage: "maskedInfoMobile"
    })
  }

  login(event) {
    event.preventDefault()
    const email = this.state.email
    const password = this.state.password
    const nextPage = (typeof this.props.location.state=="undefined")?"/home":this.props.location.state.from.pathname
    Actions.login(email, password, nextPage, this.props.history)
  }

  logout() {
    Actions.logout()
  }

  resetEmail(page, event) {
    event.preventDefault()
    const email = this.state.email
    Actions.resetPassword(email)
    this.setState({
      registerActivePage: page,
      registerActiveMobilePage: page
    })
  }

  resetEmailWindow(event) {
    event.preventDefault()
    let tempState = this.state.resetEmail
    if (tempState === "false") {
      tempState = "true"
    }
    else {
      tempState = "false"
    }
    this.setState({ resetEmail: tempState });
  }

  render() {

    if (typeof this.state.uid === 'undefined') {
      return (<h2>Loading</h2>)
    }

    if (this.state.uid != "") {
      return <Redirect to = {"/" + (this.props.match.params.nextP||"Home")}/>
    }

    let mainWindow = ""
    let resetWindow = ""

    if (this.state.resetEmail === "true") {
      mainWindow = "d-none"
    }

    if (this.state.resetEmail === "false") {
      resetWindow = "d-none"
    }

    return (
      <div>
          <Navbar displayNo="no"/>
           <div id="body">
            <div id="loginForm" className="card section-height">
              <span>
                {/*<span className="largeLogin">
                  {/*Default login page
                  <div className={this.isActiveResponsive("loginMobile")}>
                    <span className="form-row">
                      <span className="form-group col-sm-6">
                        <h1 id="logincard-title-style">Login</h1>
                      </span>
                      
                       <span id="marginAbove" className="form-group col-sm-6">
                        <button className="btn btn-primary" onClick={(event) =>{this.buttonNav("enterAddress",event)}}>New users register here</button>
                      </span>
                      
                    </span>
                    <form>
                      <div className="form-row">
                        <div className="form-group col-sm-6">
                            <label className="labelClass">Email</label>
                            <input type="email" className="form-control" name = "email" value={this.state.email} onChange={this.handleInputChange.bind(this)}/>
                        </div>
                        <div className="col-sm-6"></div>
                        <div className="form-group col-sm-6">
                            <label className="labelClass">Password</label>
                            <input type="password" className="form-control" name = "password" value={this.state.password} onChange={this.handleInputChange.bind(this)}/>
                        </div>
                      </div>
                      <div id="marginBottom" className="d-flex justify-content-between">
                        <div>
                          <button className="btn btn-primary" onClick={(event) => {this.login(event)}}>Login</button>
                        </div>
                        <div>
                          <button className="btn btn-primary" onClick={(event) => {this.buttonNav("emailReset", event)}}>Reset Password</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </span>*/}
                
                <span>
                {/* Login Responsive Screen */}
                  <div className={this.isActive("login")}>
                    <div className="d-flex justify-content-sm-center">
                      <div className="col-sm-8">
                        <span className="row">
                          <h1 id="login-left">Log in</h1>
                          {/* <span id="register-right" className="text-center">
                            <button className="btn btn-primary" onClick={(event) =>{this.buttonNav("enterAddress",event)}}>Register</button>
                          </span>*/}
                        </span>
                        <hr className="hr-overwrite" />
                        <form>
                          <div className="form-group col-sm-12">
                              <label className="labelClass">Email</label>
                              <input type="email" className="form-control" name="email" value={this.state.email} onChange={this.handleInputChange.bind(this)}/>
                          </div>
                          <div className="form-group col-sm-12">
                              <label className="labelClass">Password</label>
                              <input type="password" className="form-control" name="password" value={this.state.password} onChange={this.handleInputChange.bind(this)}/>
                          </div>
                          <span className="row d-flex justify-content-between">
                            <div className="form-group">
                              <button id="pixel-left" className="btn btn-primary button-color" onClick={(event) => {this.login(event)}}>Log in</button>
                            </div>
                            <div className="form-group">
                              <span className="reset-color" id="pixel-right" onClick={(event) => {this.buttonNav("emailReset", event)}}><a>Reset Password</a></span>
                            </div>
                          </span>
                        </form>
                      </div>
                    </div>
                  </div>
                </span>
                </span>
                
                <span> 
                   {/*<span>
                   Reset email page
                    <div className={this.isActive("emailReset")}>
                      <h1 id="logincard-title-style">Reset Email</h1>
                      <form>
                        <div className="form-row">
                            <div className="form-group col-sm-6">
                                <label className="labelClass">Email</label>
                                <input type="email" className="form-control" value={this.state.email} onChange={this.handleInputChange.bind(this)} placeholder="Enter the email registered to your account"/>
                            </div>
                        </div>
                        <div  className="form-row">
                          <div className="form-group col-sm-2">
                            <button className="btn btn-primary" onClick={(event) => {this.buttonNav("login", event)}}>Previous</button>
                          </div>
                          <div className="form-group col-sm-2">
                            <button className="btn btn-primary" onClick={(event) =>{this.resetEmail("sentEmail",event)}}>Request password reset</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </span> */}
                  
                  <span>
                    {/*Request Password Reset Responsive Screen*/}
                    <div className={this.isActive("emailReset")}>
                      <div className="maximum-width">
                        <div className="col-sm-12">
                          <h1 id="logincard-title-style" className="header-padding">Request Password Reset</h1>
                          
                          <hr className="hr-overwrite" />
                          
                          <form id="userEmailResetForm">
                            <div className="form-row">
                                <div className="form-group col-sm-12">
                                    <label className="labelClass">Email</label>
                                    <input type="email" className="form-control" name="email" value={this.state.email} onChange={this.handleInputChange.bind(this)} placeholder="Enter Email"/>
                                </div>
                            </div>
                            <div className="margin-div row d-flex justify-content-between">
                              <button className="btn btn-primary" onClick={(event) => {this.buttonNav("login", event)}}>
                                <FontAwesome name='arrow-left' size="lg"/>
                              </button>
                              <button className="btn btn-primary" onClick={(event) =>{this.resetEmail("sentEmail",event)}}>Submit Request</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </span>
                </span>
              
                <span>
                  <span className="largeLogin">
                  {/*Address and Autocomplete Screen*/} 
                  <div className={this.isActive("enterAddress")}>
                  <h1 id="logincard-title-style">Enter Address</h1>
                    <div id="registerForm">
                      <div className="form-row">
                        <div className="form-group col-sm-6">
                            <label className="labelClass">Property Address</label>
                              <PlacesAutocomplete
                                value={this.state.address}
                                onChange={this.handleChangeAddress.bind(this)}
                              >
                                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                  <div className="form-row">
                                    
                                      <span className="form-group col-sm-9">
                                        <input
                                          {...getInputProps({
                                            placeholder: 'Please enter the property address',
                                            className: 'location-search-input form-control',
                                            type:'text'
                                          })}
                                        />
                                      </span>
                                      
                                      <span className="form-group col-sm-3">
                                        <input className="location-search-input form-control" 
                                        value={this.state.unitNumber} onChange={this.handleChangeUnit.bind(this)}
                                        type="text" className="form-control" placeholder="Apt#" />
                                      </span>
                                    
                                    <div className="autocomplete-dropdown-container">
                                      {suggestions.map(suggestion => {
                                        const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                        
                                          const style = suggestion.active
                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        
                                        return (
                                          <div {...getSuggestionItemProps(suggestion, { className, style})}>
                                            <span>{suggestion.description}</span>
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </div>
                                )}
                            </PlacesAutocomplete>
                          <span className="form-row">
                            <span className="form-group col-sm-4">
                              <button className="btn btn-primary" onClick={(event) =>{this.buttonNav("login",event)}}>&lt;Previous</button>
                            </span>
                            <span className="form-group col-sm-4">
                              <button className="btn btn-primary" onClick={this.lookupMaskedInfo.bind(this)}>Next&gt;</button>
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  </span>
                  
                  <span className="smallLogin">
                    {/*Autocomplete Address Responsive Screen*/}
                    <div className={this.isActiveResponsive("enterAddressMobile")}>
                    <h1 id="logincard-title-style">Enter Address</h1>
                      <div id="registerForm">
                        <div className="form-row">
                          <div className="form-group col-sm-6">
                              <label className="labelClass">Property Address</label>
                                <PlacesAutocomplete
                                  value={this.state.address}
                                  onChange={this.handleChangeAddress.bind(this)}
                                >
                                  {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                    <div className="form-row">
                                        <span className="form-group col-sm-9">
                                          <input
                                            {...getInputProps({
                                              placeholder: 'Enter address',
                                              className: 'location-search-input form-control',
                                              type:'text'
                                            })}
                                          />
                                        </span>
                                        <span className="form-group col-sm-3">
                                          <input className="location-search-input form-control" 
                                          value={this.state.unitNumber} onChange={this.handleChangeUnit.bind(this)}
                                          type="text" className="form-control" id="formGroupExampleInput" placeholder="Apt#" />
                                        </span>
                                      <div className="autocomplete-dropdown-container">
                                        {suggestions.map(suggestion => {
                                          const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                            const style = suggestion.active
                                              ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                              : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                          
                                          return (
                                            <div {...getSuggestionItemProps(suggestion, { className, style})}>
                                              <span>{suggestion.description}</span>
                                            </div>
                                          )
                                        })}
                                      </div>
                                    </div>
                                  )}
                              </PlacesAutocomplete>
                            <div className="d-flex justify-content-between">  
                                <button className="btn btn-primary" onClick={(event) =>{this.buttonNav("loginMobile",event)}}><FontAwesome name='arrow-left' size="lg"/></button>
                                <button className="btn btn-primary" onClick={this.lookupMaskedInfo.bind(this)}><FontAwesome name='arrow-right' size="lg"/></button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                </span>
                
                <span>
                  <span className="largeLogin">
                    {/*Masked info page*/}
                    <div className={this.isActive("maskedInfo")}>
                      <h1 id="logincard-title-style">Select Recognized Information</h1>
                        <div className="row">
                          <div className="col-sm-12">
                            <form onSubmit={this.handleFormSubmit.bind(this)}>
                              <div className="radio">
                                <label>
                                  <input type="radio" value="option1" checked={this.state.selectedOption === 'option1'} onChange={this.handleOptionChange.bind(this)} />
                                  Masked Email
                                </label>
                              </div>
                              <div className="radio">
                                <label>
                                  <input type="radio" value="option2" checked={this.state.selectedOption === 'option2'} onChange={this.handleOptionChange.bind(this)}/>
                                  Masked Phone Number 
                                </label>
                              </div>
                              <div className="radio">
                                <label>
                                  <input type="radio" value="option3" checked={this.state.selectedOption === 'option3'} onChange={this.handleOptionChange.bind(this)}/>
                                  Don't recognize
                                </label>
                              </div>
                              <div className="form-row">
                                <div className="form-group col-sm-2"><button className="btn btn-primary" onClick={(event) =>{this.buttonNav("enterAddress",event)}}>&lt;Previous</button></div>
                                <div className="form-group col-sm-2"><button className="btn btn-primary" onClick={(event) =>{this.handleFormSubmit(event)}}>Next&gt;</button></div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </span>
                
                    <span className="smallLogin">
                    {/*Masked Info Responsive Screen*/}
                    <div className={this.isActiveResponsive("maskedInfoMobile")}>
                      <h1 id="logincard-title-style">Select Recognized Information</h1>
                      <div className="row">
                        <div className="col-sm-12">
                          <form onSubmit={this.handleFormSubmit.bind(this)}>
                            <div className="radio">
                              <label>
                                <input type="radio" value="option1" checked={this.state.selectedOption === 'option1'} onChange={this.handleOptionChange.bind(this)} />
                                Masked Email
                              </label>
                            </div>
                            <div className="radio">
                              <label>
                                <input type="radio" value="option2" checked={this.state.selectedOption === 'option2'} onChange={this.handleOptionChange.bind(this)}/>
                                Masked Phone Number 
                              </label>
                            </div>
                            <div className="radio">
                              <label>
                                <input type="radio" value="option3" checked={this.state.selectedOption === 'option3'} onChange={this.handleOptionChange.bind(this)}/>
                                Don't recognize
                              </label>
                            </div>
                            <div className="d-flex justify-content-between">
                              <button className="btn btn-primary" onClick={(event) =>{this.buttonNav("enterAddressMobile",event)}}><FontAwesome name='arrow-left' size="lg"/></button>
                              <button className="btn btn-primary" onClick={(event) =>{this.handleFormSubmit(event)}}><FontAwesome name='arrow-right' size="lg"/></button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </span>
                </span>
                
                <span>
                  <span className="largeLogin">
                    {/*Send Email Screen*/}
                    <div className={this.isActive("sentEmail")}>
                      <SentEmail />
                    </div>
                  </span>
                  
                  <span className="smallLogin">
                    { /*Send Email Responsive Screen*/ }
                    <div className={this.isActiveResponsive("sentEmailMobile")}>
                      <h1 id="logincard-title-style">Thank you!</h1>
                      <div className="form-row">
                          <div className="form-group col-sm-8">
                              <p>An email has been sent to the email address entered.</p>
                          </div>
                          <div className="form-group col-sm-8">
                              <button className="btn btn-primary" onClick={(event) =>{this.buttonNav("loginMobile",event)}}>Return to log-in</button>
                          </div>
                      </div>
                    </div>
                  </span>
                </span>
                    
                <span>
                  <span className="largeLogin">
                    {/*Request Access Screen*/}
                    <div className={this.isActive("requestAccess")}>
                      <RequestAccess />
                    </div>
                  </span>
                  
                  <span className="smallLogin">
                    {/*Request Access Responsive Screen*/}      
                    <div className={this.isActiveResponsive("requestAccessMobile")}>
                      <h1 id="logincard-title-style">Request Access</h1>
                      <div className="form-group">
                          <div className="form-group col-sm-10">
                            <label htmlFor="inputAddress2" className="labelClass">Name</label>
                            <input type="text" className="form-control" />
                          </div>
                          <div className="form-group col-sm-10">
                            <label htmlFor="inputAddress" className="labelClass">Address</label>
                            <input type="text" className="form-control" />
                          </div>
                          <div className="form-group col-sm-10">
                            <label htmlFor="phoneNumber" className="labelClass">Phone</label>
                            <input type="text" className="form-control" />
                          </div> 
                          <div className="form-group col-sm-10">
                            <label htmlFor="email" className="labelClass">Email</label>
                            <input type="text" className="form-control" />
                          </div>
                      </div>
                      <div className="form-group col-sm-7">
                          <button className="btn btn-primary" onClick={(event) =>{this.buttonNav("thankYouMobile",event)}} type="submit">Submit</button>
                      </div>
                    </div>
                  </span>
                </span>
              
                <span>
                  <span className="largeLogin">
                  { /*Success Screen*/ }
                    <div className={this.isActive("success")}>
                      <Success />
                    </div>
                  </span>
                  <span className="smallLogin">
                    { /*Success Responsive Screen*/ }
                    <div className={this.isActiveResponsive("successMobile")}>
                      <h1 id="logincard-title-style">Success!</h1>
                      <div className="form-row">
                          <div className="form-group col-sm-8">
                              <p>Check your email. Log-in information was emailed to you.</p>
                          </div>
                          <div className="form-group col-sm-8">
                              <button className="btn btn-primary" onClick={(event) =>{this.buttonNav("loginMobile",event)}}>Click here to log in</button>
                          </div>
                      </div>
                    </div>
                  </span>
                </span>
                
                <span>
                  <span className="largeLogin">
                    { /*Thank You Screen*/ }
                    <div className={this.isActive("thankYou")}>
                      <ThankYou />
                    </div>
                  </span>
            
                  <span className="smallLogin">
                    { /*Thank You Responsive Screen*/}
                    <div className={this.isActiveResponsive("thankYouMobile")}>
                      <h1 id="logincard-title-style">Thank you!</h1>
                      <div className="form-row">
                          <div className="form-group col-sm-8">
                              <p>We'll get back to you shortly.</p>
                          </div>
                          <div className="form-group col-sm-8">
                              <a href="/"><button className="btn btn-primary">Return to Main Page</button></a>
                          </div>
                      </div>
                    </div>
                  </span>
                </span>
            </div> 
            </div>
          <Footer/>
        </div>
    );
  }
}
