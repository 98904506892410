import { Link } from "react-router-dom";
import Flux from "@4geeksacademy/react-flux-dash";
//import FontAwesome from 'react-fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Footer from '../components/Footer.jsx';
import Header from '../components/Header.jsx';
import Navbar from '../components/Navbar.jsx';
import React from "react"
import Store from "../store/Store.jsx";

export default class Home extends Flux.View {

    constructor(props) {
        super(props);
        this.state = {
        }
        
    }

    // Lifecycle methods

    componentWillMount() {
       
    }

    // Views Methods

    render() {
        return (
            <div>
                <Navbar active="home" />
               <Header />
                <div className="col-sm-9 max-width-div">
                    <div id="actionCards" className="d-flex flex-row justify-content-around align-items-center flex-wrap">
                        <Link to="/profile">
                            <div key="1" className="card justify-content-center align-items-center" id="card1">
                                <div className="card-body text-center">
                                    <FontAwesomeIcon icon='user-circle' size="2x"/>
                                    <h4 className="card-title card-title-style">Profile</h4>
                                </div>
                            </div>
                        </Link>    
                        <Link to="/statement">
                            <div key="2" className="card justify-content-center align-items-center actionCards" id="card2">
                                <div className="card-body text-center ">
                                    <FontAwesomeIcon icon="list-alt" size="2x" />
                                    <h4 className="card-title card-title-style ">Statement</h4>
                                </div>
                            </div>
                        </Link>
                        <a href="https://www.clickpay.com/Home.aspx">
                            <div key="3" className="card justify-content-center align-items-center actionCards" id="card3">
                                <div className="card-body text-center">
                                    <FontAwesomeIcon icon='wallet' size="2x"/>
                                    <h4 className="card-title card-title-style ">Pay</h4>
                                </div>
                            </div>
                        </a>    
                        <Link to="/documents">
                            <div key="4" className="card justify-content-center align-items-center actionCards" id="card4">
                                <div className="card-body text-center">
                                    <FontAwesomeIcon icon='file-alt' size="2x"/>
                                    <h4 className="card-title card-title-style ">Documents</h4>
                                </div>
                            </div>
                        </Link>    
                        <a href="https://office.smartwebs.net/Arc/SWForms/index.cshtml#/forms/0F1CC48A-8181-E911-80FC-000C2903E046" target="_blank">
                            <div key="5" className="card justify-content-center align-items-center actionCards" id="card5">
                                <div className="card-body text-center">
                                    <FontAwesomeIcon icon='paint-roller' size="2x"/>
                                    <h4 className="card-title card-title-style ">Architectual Modification</h4>
                                </div>
                            </div>
                        </a>   
                        <a href="https://fs23.formsite.com/AFFINITY/form4/index.html" target="_blank">
                            <div key="6" className="card justify-content-center align-items-center" id="card6">
                                <div className="card-body text-center">
                                    <FontAwesomeIcon icon='user-check' size="2x"/>
                                    <h4 className="card-title card-title-style">Sales and Rental Application</h4>
                                </div>
                            </div>
                        </a>    
                    </div>
                
                    <div className="col-sm-9 max-width-div">
                        <div className="card margin-bottom-home">
                            <div className="card-header card-title-header"><h1 className="card-title">About Our Community</h1></div>
                            <div>
                                <span className="largeParagraph">
                                    <p id="aboutOurCommunity">
                                        Flanked to the East by Pinelands, Village of Palm Bay is a comfortable community located in Florida City on the 
                                        Southernmost end of Homestead. Our community enjoys amenities in the way of two playgrounds, pool, volleyball and basketball courts, secured gates, and pavilion great 
                                        for parties and gatherings. When outside of Village of Palm Bay, our residents take advantage of being in such close 
                                        proximity to an awesome shopping experience at the Florida Keys Outlet Marketplace. Should the urge to be one with 
                                        nature arise, visit William J. Fasulo park where endemic wildlife may be seen during your morning jog. Residents of 
                                        Village of Palm Bay also enjoy having Key West as it’s extended back yard. Ever wanted a quick getaway without the long 
                                        travel time? Well the residents of Village of Palm Bay enjoy that option 365 days of the year.
                                    </p>
                                </span>
                                <span className="smallParagraph">
                                    <input type="checkbox" className="read-more-state" id="post-1" />
                                        <p id="aboutOurCommunity" className="read-more-wrap">
                                            Flanked to the East by Pinelands, Village of Palm Bay is a comfortable community located in Florida City on the 
                                            Southernmost end of Homestead. Our community enjoys amenities in the way of two playgrounds, pool, volleyball and basketball courts, secured gates, and pavilion great
                                            for parties and gatherings. When outside of Village of Palm Bay, our residents take advantage of being in such close 
                                            <span className="read-more-target">
                                            proximity to an awesome shopping experience at the Florida Keys Outlet Marketplace. Should the urge to be one with 
                                            nature arise, visit William J. Fasulo park where endemic wildlife may be seen during your morning jog. Residents of 
                                            Village of Palm Bay also enjoy having Key West as it’s extended back yard. Ever wanted a quick getaway without the long 
                                            travel time? Well the residents of Village of Palm Bay enjoy that option 365 days of the year.
                                            </span>
                                        </p>
                                    <label htmlFor="post-1" className="read-more-trigger"></label>
                                </span>
                            </div>
                        </div>
                        {/*<div className="card margin-bottom-home">
                            <div className="card-header card-title-header"><h1>Questions And Answers</h1></div>                 
                            <div className="accordion" id="accordion">
                              <div>
                                <div className="card-header" id="headingOne">
                                  <h5 className="mb-0">
                                    <a className="collapsed showHide remove-links" role="button" data-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                      How do I obtain clickers?
                                    </a>
                                  </h5>
                                </div>
                                <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                  <div className="card-body">
                                    <a>You may request access via our website by clicking <a href="https://fs23.formsite.com/AFFINITY/form22/index.html" target="_blank">HERE</a> or calling our office at 305-325-4243 Monday through Friday from 9:00am to 5:00pm. The cost of the control is $35.</a>
                                  </div>
                                </div>
                              </div>
                              
                              <div>
                                <div className="card-header" id="headingTwo">
                                  <h5 className="mb-0">
                                    <a className="collapsed showHide remove-links" role="button" data-toggle="collapse" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                      What is the parking policy?
                                    </a>
                                  </h5>
                                </div>
                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                  <div className="card-body">
                                    Parking is not allowed on the street or the blocking the sidewalk. 
                                  </div>
                                </div>
                                
                                 <div className="card-header" id="headingThree">
                                  <h5 className="mb-0">
                                    <a className="collapsed showHide remove-links" role="button" data-toggle="collapse" href="#collapseThree" aria-expanded="false" aria-controls="collapseTwo">
                                      How do I update my auto payments?
                                    </a>
                                  </h5>
                                </div>
                                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                  <div className="card-body">
                                    Please click <Link to="/pay-now">HERE</Link> then select "Manage Auto Pay" on the following page.
                                  </div>
                                </div>
                                
                                <div className="card-header" id="headingFour">
                                  <h5 className="mb-0">
                                    <a className="collapsed showHide remove-links" role="button" data-toggle="collapse" href="#collapseFour" aria-expanded="false" aria-controls="collapseTwo">
                                      What is the trash pick up schedule?
                                    </a>
                                  </h5>
                                </div>
                                <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                  <div className="card-body">
                                    Regular trash pick up is Monday and Thursdays. Recycling is picked up on Thursdays.
                                  </div>
                                </div>
                                
                              </div>
                            </div>
                        </div>*/}
                    </div>
                </div>  
             <Footer/>
            </div>
        );
    }
}
