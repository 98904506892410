import Flux from "@4geeksacademy/react-flux-dash";
import FontAwesome from 'react-fontawesome';
import Footer from '../Footer.jsx';
import Navbar from '../Navbar.jsx';
import React from "react";
import Store from "../../store/Store.jsx";
import { Link, Redirect } from "react-router-dom";

export default class Notices extends Flux.View {
  constructor(props) {
    super(props);
    this.state = (
      Store.getLoggedInInfo()
    )
  }

  // Lifecycle methods

  componentWillMount() {
    this.setState(
      Store.getLoggedInInfo()
    )
    this.bindStore(Store, () => {
      this.setState(
        Store.getLoggedInInfo()
      )
    })
  }

  // Views Methods

  render() {
  
    return (
      <div id="body">
        <Navbar active=""/>
          <span className="smallStatement">
            <div className="card" id="body">
              <div className="margin-style-user-back-btn">
                <Link to="/Documents"> 
                    <button type="button" className="btn btn-primary button-color">
                      <FontAwesome name='arrow-left' size="lg"/>
                    </button>
                </Link> 
              </div>
              <div className="card div-profile text-center"><h1> Notices </h1></div>
                <div className="margin-span-style">
                  <ul className="menu-items">
                      <li className="menu-items">
                          <a className="parent" href="https://fs23.formsite.com/AFFINITY/files/f-6-0-10074145_qBvnK7FK_guide-to-hurricane-readiness.pdf" target="_blank">
                            <FontAwesome name='file-alt' size="lg"/>
                              &nbsp;Hurricane Guide
                            <span className="float-right">
                              <FontAwesome name='chevron-right' size="lg"/>
                            </span>
                          </a>
                        </li>
                      
                  </ul>
                </div>
              </div>
            </span>
        <Footer />
      </div>
    );
  }
}
