import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Flux from "@4geeksacademy/react-flux-dash";
import React from "react";

export default class Board extends Flux.View {
  render() {
    return (
      <div>
        <table className="table table-hover large">
          <tbody>
            <tr>
              <td><span className="bold">President</span></td>
              <td className="no-bottom-border" colSpan="2">Victor D Silva</td>
            </tr>
           {/* <tr>
              <td><span className="bold">Vice President</span></td>
              <td className="no-bottom-border" colSpan="2">Carlos Arroyo</td>
            </tr>*/}
            <tr>
              <td><span className="bold">Secretary</span></td>
              <td className="no-bottom-border" colSpan="2">Carmen Santiago</td>
            </tr>
            <tr>
              <td><span className="bold">Treasurer</span></td>
              <td className="no-bottom-border" colSpan="2">Carmen Santiago</td>
            </tr>
           <tr>
              <td><span className="bold">Director</span></td>
              <td className="no-bottom-border" colSpan="2">Lisnelys Garcia</td>
           </tr>
          </tbody>
        </table>
        <span className="small">
        <table className="table table-hover">
          <tbody>
            <tr>
              <td><span className="bold">President</span></td>
            </tr>
            <tr>
              <td className="no-bottom-border" colSpan="2">Victor D Silva</td>
            </tr>
          {/*  <tr>
              <td><span className="bold">Vice President</span></td>
            </tr>
            <tr>
              <td className="no-bottom-border" colSpan="2">Carlos Arroyo</td>
            </tr>*/}
            <tr>
              <td><span className="bold">Secretary</span></td>
            </tr>
            <tr>
              <td className="no-bottom-border" colSpan="2">Carmen Santiago</td>
            </tr>
            <tr>
              <td><span className="bold">Treasurer</span></td>
            </tr>
            <tr>
              <td className="no-bottom-border" colSpan="2">Carmen Santiago</td>
            </tr>
           <tr>
              <td><span className="bold">Director</span></td>
            </tr>
            <tr>
              <td className="no-bottom-border" colSpan="2">Lisnelys Garcia</td>
            </tr>
          </tbody>
        </table> 
      </span>
      </div>
    );
  }
}
