import Actions from "../../actions/Actions.jsx";
import Flux from "@4geeksacademy/react-flux-dash";
import FontAwesome from 'react-fontawesome';
import Footer from '../Footer.jsx';
import Navbar from '../Navbar.jsx';
import React from "react";
import {Session} from '../../actions/utility/session';
import Store from "../../store/Store.jsx";
import { Link, Redirect } from "react-router-dom";

export default class LoginUpdate extends Flux.View {

  constructor(props) {
    super(props);
 this.state = {
      email:'',
      oldPassword: '',
      newPassword:''
    };
  }
 
 componentWillMount(){
   const user = Session.store.getSession()
   Actions.getEmail(user)
   this.bindStore(Store, () => {
      const email = Store.getEmail()
      this.setState ({
        email:email
      })
    })
  }
  handleInputChange({target}){
    this.setState({
      [target.id]: target.value
    })
    console.log("working")
  }
  resetPassword(event){
    event.preventDefault()
    const sessionToken = Session.store.getSession().access_token;
    let oldPassword = this.state.oldPassword
    let newPassword = this.state.newPassword
    Actions.resetCurrentPassword(sessionToken,oldPassword,newPassword)
    this.setState ({
      oldPassword: '',
      newPassword: ''
    })
  }
  
  // Views Methods
  render() {
    return (
      <div>
        <Navbar active=""/>
        <div className="margin-style-user" id="body">
          <div>
            <Link to="/Profile"> 
                <button type="button" className="btn btn-primary button-color">
                  <FontAwesome name='arrow-left' size="lg"/>
                </button>
            </Link>
          </div>
          <div className="card card-heading text-center">
            <h1>Change Password</h1>
          </div>
            <form id="paddingForm">
              <div className="form-row">
                <div className="form-group col-sm-6">
                  <label htmlFor="email" className="labelClass">Email</label>
                  <input type="text" className="form-control" value={this.state.email} />
                </div>
                <div className="form-group col-sm-6">
                </div>
                <div className="form-group col-sm-6">
                  <label htmlFor="oldPassword" className="labelClass">Old Password</label>
                  <input type="text" className="form-control" id="oldPassword" onChange={this.handleInputChange.bind(this)} value={this.state.oldPassword} />
                </div>
                <div className="form-group col-sm-6">
                </div>
                <div className="form-group col-sm-6">
                  <label htmlFor="newPassword" className="labelClass">New Password</label>
                  <input type="text" className="form-control" id="newPassword" onChange={this.handleInputChange.bind(this)} value={this.state.newPassword} />
                </div>
              </div>
              <div className="editable">
                <button className="btn btn-primary" onClick={(event) =>{this.resetPassword(event)}}>Submit</button>
              </div>
            </form>  
          </div>
          <Footer />
      </div>
    );
  }
}
