import Flux from "@4geeksacademy/react-flux-dash";
import FontAwesome from 'react-fontawesome';
import Footer from '../Footer.jsx';
import Navbar from '../Navbar.jsx';
import React from "react";
import Store from "../../store/Store.jsx";
import { Link, Redirect } from "react-router-dom";

export default class Meetings extends Flux.View {
  constructor(props) {
    super(props);
    this.state = (
      Store.getLoggedInInfo()
    )
  }

  // Lifecycle methods

  componentWillMount() {
    this.setState(
      Store.getLoggedInInfo()
    )
    this.bindStore(Store, () => {
      this.setState(
        Store.getLoggedInInfo()
      )
    })
  }

  // Views Methods

  render() {
   
     return (
      <div>
        <Navbar active=""/>
        <span className="smallStatement">
          <div className="card" id="body">
            <div className="margin-style-user-back-btn">
              <Link to="/Documents"> 
                  <button type="button" className="btn btn-primary button-color">
                    <FontAwesome name='arrow-left' size="lg"/>
                  </button>
              </Link> 
            </div>
              <div className="card div-profile text-center"><h1> Meetings </h1></div>
              <div className="margin-span-style">
                <ul className="menu-items">
                    <li className="menu-items">
                      <a className="parent" href="https://fs23.formsite.com/AFFINITY/files/f-6-0-10102161_dLTAl57b_2018_0830_Annual_Meeting_Agenda_01-09-18.pdf" target="_blank">
                        <FontAwesome name='file-alt' size="1x"/>
                          &nbsp;2018_0109 Annual Meeting
                        <span className="float-right">
                          <FontAwesome name='chevron-right' size="1x"/>
                        </span>
                      </a>
                    </li>
                    <li className="menu-items">
                      <a className="parent" href="https://fs23.formsite.com/AFFINITY/files/f-6-0-10102164_Ikvs3GXN_2018_0504_Meeting_Notice.pdf" target="_blank">
                        <FontAwesome name='file-alt' size="1x"/>
                          &nbsp;2018_0504 Board of Directors Meeting
                        <span className="float-right">
                          <FontAwesome name='chevron-right' size="1x"/>
                        </span>
                      </a>
                    </li> 
                    <li className="menu-items">
                      <a className="parent" href="https://fs23.formsite.com/AFFINITY/files/f-33-2-10339312_Wc4GZydh_2018_1115_Notice_of_Proposed_2019_Budget_English__Spanish_-_Village_of_Palm_Bay.pdf" target="_blank">
                        <FontAwesome name='file-alt' size="1x"/>
                          &nbsp;2018_1129 Budget Meeting
                        <span className="float-right">
                          <FontAwesome name='chevron-right' size="1x"/>
                        </span>
                      </a>
                    </li> 
                </ul>
              </div>
            </div>
          </span>
        <Footer />
      </div>
    );
  }
}
