import { Link } from "react-router-dom";
import Flux from "@4geeksacademy/react-flux-dash";
//import FontAwesome from 'react-fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Footer from '../components/Footer.jsx';
import Header from '../components/Header.jsx';
import Navbar from '../components/Navbar.jsx';
import React from "react"
import Store from "../store/Store.jsx";

export default class Home extends Flux.View {

    constructor(props) {
        super(props);
        this.state = {
            payOption:"",
            numberOfCards:"",
            numberOfStickers:""
        }
        
    }

    // Lifecycle methods

    componentWillMount() {
       
    }
    payOption(event) {
        let option = event.target.value
        this.setState = {payOption: option}
        console.log("here")
    }
    handleClick() {}

    // Views Methods

    render() {
        let selectedOption = this.state.payOption
        let displaySection
        
        if (selectedOption == 'accessControl') {
            displaySection = 
                <div className="form-group col-sm-9">
                    <div>
                        <label><strong>Number of Cards Needed</strong></label>
                        <select className="form-control" value={this.state.value} onChange={this.handleClick.bind(this)} required>
                          <option value='' >Select from dropdown menu</option>
                          <option value='not posted'>My payment is not posted on my account</option>
                          <option value='returned'>My payment was returned back to me</option>
                          <option value='late fee'> I was charged a late fee</option>
                          <option value='disagree'>I disagree with the pending balance</option>
                          <option value='charged'>I sold my property and I am still being charged</option>
                          <option value='balance'>I just bought this property and the account has a balance</option>
                          <option value='other'>Other</option>
                        </select>
                    </div>
                    <div>
                        <label><strong>Number of stickers needed</strong></label>
                        <select className="form-control" value={this.state.value} onChange={this.handleClick.bind(this)} required>
                          <option value='' >Select from dropdown menu</option>
                          <option value='not posted'>My payment is not posted on my account</option>
                          <option value='returned'>My payment was returned back to me</option>
                          <option value='late fee'> I was charged a late fee</option>
                          <option value='disagree'>I disagree with the pending balance</option>
                          <option value='charged'>I sold my property and I am still being charged</option>
                          <option value='balance'>I just bought this property and the account has a balance</option>
                          <option value='other'>Other</option>
                        </select>
                    </div>
                </div>
        }
      
       return (
            <div>
                <Navbar active="home" />
                <div id="body" className="card contact-info">
                <form className="row h-100 justify-content-center align-items-center">
                    <label><strong>Please select the payment type</strong></label>
                    <select className="form-control" value={this.state.value} onChange={this.payOption.bind(this)} required>
                      <option value='' >Select from dropdown menu</option>
                      <option value='accountBalance'>My assessment balance</option>
                      <option value='accessControl'>Access control device</option>
                      <option value='clubhouseFee'>Clubhouse rental fee</option>
                      <option value='trashCan'>Trash can replacement</option>
                    </select>
                </form>
                </div>
                {displaySection}
            <Footer/>
            </div>
    );
    }
}
