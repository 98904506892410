import Flux from "@4geeksacademy/react-flux-dash";
import FontAwesome from 'react-fontawesome';
import Footer from '../Footer.jsx';
import Navbar from '../Navbar.jsx';
import React from "react";
import Store from "../../store/Store.jsx";
import { Link, Redirect } from "react-router-dom";


export default class GoverningDocuments extends Flux.View {
  constructor(props) {
    super(props);
    this.state = (
      Store.getLoggedInInfo()
    )
  }

  // Lifecycle methods

  componentWillMount() {
    this.setState(
      Store.getLoggedInInfo()
    )
    this.bindStore(Store, () => {
      this.setState(
        Store.getLoggedInInfo()
      )
    })
  }

  // Views Methods

  render() {
    
    return (
      <div>
        <Navbar active=""/>
          <span className="smallStatement">
              <div className="card" id="body">
                <div className="margin-style-user-back-btn">
                  <Link to="/Documents"> 
                      <button type="button" className="btn btn-primary button-color">
                        <FontAwesome name='arrow-left' size="lg"/>
                      </button>
                  </Link> 
                </div>
                <div className="card div-profile text-center"><h1> Governing Documents </h1></div>
                  <div className="margin-span-style">
                    <ul className="menu-items">
                        <li className="menu-items">
                          <a className="parent" href="https://fs23.formsite.com/AFFINITY/files/f-6-0-10138195_k4dotghV_Declaration.pdf" target="_blank">
                            <FontAwesome name='file-alt' size="lg"/>
                              &nbsp;Declaration
                            <span className="float-right">
                              <FontAwesome name='chevron-right' size="lg"/>
                            </span>
                          </a>
                        </li>
                        <li className="menu-items">
                          <a className="parent" href="https://fs23.formsite.com/AFFINITY/files/f-6-0-10138199_Gvpxf8RL_Articles_Of_Incorporation.pdf" target="_blank">
                            <FontAwesome name='file-alt' size="lg"/>
                              &nbsp;Articles of Incorporation
                            <span className="float-right">
                              <FontAwesome name='chevron-right' size="lg"/>
                            </span>
                          </a>
                        </li> 
                        <li className="menu-items">
                         <a className="parent" href="https://fs23.formsite.com/AFFINITY/files/f-6-0-10138200_oYH0de6w_ByLaws.pdf" target="_blank">
                            <FontAwesome name='file-alt' size="lg"/>
                              &nbsp;Bylaws
                            <span className="float-right">
                              <FontAwesome name='chevron-right' size="lg"/>
                            </span>
                          </a>
                        </li>
                        <li className="menu-items">
                         <a className="parent" href="https://fs23.formsite.com/AFFINITY/files/f-6-0-10138202_V4EqoGsL_Rules__Regulations.pdf" target="_blank">
                            <FontAwesome name='file-alt' size="lg"/>
                              &nbsp;Rules And Regulations
                            <span className="float-right">
                              <FontAwesome name='chevron-right' size="lg"/>
                            </span>
                          </a>
                        </li>
                        
                    </ul>
                  </div>
                </div>
            </span>
        <Footer />
      </div>
    );
  }
}
