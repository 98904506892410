import Flux from "@4geeksacademy/react-flux-dash";
import FontAwesome from 'react-fontawesome';
import Footer from '../Footer.jsx';
import Navbar from '../Navbar.jsx';
import React from "react";
import Store from "../../store/Store.jsx";
import { Link, Redirect } from "react-router-dom";

export default class Pets extends Flux.View {

  constructor(props) {
    super(props);
    this.state = (
      Store.getLoggedInInfo()
    )
  }

  // Lifecycle methods

  componentWillMount() {
    this.setState(
      Store.getLoggedInInfo()
    )
    this.bindStore(Store, () => {
      this.setState(
        Store.getLoggedInInfo()
      )
    })
  }

  // Views Methods
  render() {
    if (this.state.uid === "") return (<Redirect to="login/pets"/>)
    return (
      <div>
        <Navbar active=""/>
        <div className="margin-style-user">
        <div id="contactPageCardStyle" className="card col-sm-12">
          <div className="margin-style-user-back">
            <Link to="/Profile"> 
                <button type="button" className="btn btn-primary">
                  <FontAwesome name='arrow-left' size="1x"/>
                </button>
            </Link> 
          </div>
          <div className="card card-heading text-center">
            <h1>Pets</h1>
          </div>
            <form id="paddingForm">
              <div className="form-row">
                <div className="form-group col-sm-12">
                  <label for="inputAddress2" className="labelClass">Name</label>
                  <input type="text" className="form-control" value={this.state.pets.name} />
                </div>
                <div className="form-group col-sm-12">
                  <label for="inputAddress" className="labelClass">Breed</label>
                  <input type="text" className="form-control" value={this.state.pets.breed} />
                </div>
                <div className="form-group col-sm-12">
                  <label for="inputAddress" className="labelClass">Color</label>
                  <input type="text" className="form-control" id="inputAddress" value={this.state.pets.color} />
                </div>
                <div className="form-group col-sm-12">
                  <label for="inputAddress" className="labelClass">Weight</label>
                  <input type="text" className="form-control" id="inputAddress" value={this.state.pets.weight} />
                </div>
              </div>
            </form>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
